import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tasks-sales-agent-v2-item',
  templateUrl: './tasks-sales-agent-v2-item.component.html',
  styleUrls: ['./tasks-sales-agent-v2-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSalesAgentV2ItemComponent implements OnInit {
  @Output() openedTaskView: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onStartVisit(): void {
    this.openedTaskView.emit();
  }
}
