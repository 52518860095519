import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-tasks-sales-agent-tags',
  templateUrl: './tasks-sales-agent-tags.component.html',
  styleUrls: ['./tasks-sales-agent-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSalesAgentTagsComponent implements OnInit {
  @Input() tags: any;
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    // spaceBetween: 3,
    slidesPerView: 'auto',
    freeMode: true,
    pagination: false,
    centeredSlides: false,
  };
  constructor() {}

  ngOnInit(): void {}
}
