import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FinanceFullData } from '@Mesh/store/finance/finance.models';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-tree-wrapper',
  templateUrl: './tree-wrapper.component.html',
  styleUrls: ['./tree-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        })
      ),
      state(
        'show',
        style({
          height: '*',
        })
      ),
      transition('* <=> show', [animate('800ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
    trigger('animateArrowIcon', [
      state(
        '*',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'show',
        style({
          transform: 'rotate(180deg)',
        })
      ),
      transition('* => *', [animate('600ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
})
export class TreeWrapperComponent {
  TREE_DATA: FinanceFullData[] = [];

  private _financeFullData: FinanceFullData[];
  @Input() set financeFullData(financeFullData: FinanceFullData[]) {
    this._financeFullData = financeFullData;

    if (financeFullData && this.TREE_DATA.length === 0) {
      this.TREE_DATA = this._financeFullData;
      this.dataSource.data = this.TREE_DATA;
    }
  }
  get financeFullData(): FinanceFullData[] {
    return this._financeFullData;
  }

  @Output() public scrollDown = new EventEmitter();

  treeControl = new NestedTreeControl<FinanceFullData>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<FinanceFullData>();
  animationState: string = '*';
  fisrtChildNode: FinanceFullData;
  secondChildNode: FinanceFullData;

  constructor() {}

  hasChild = (_: number, node: FinanceFullData) => !!node.children && node.children.length >= 0;

  checkNode(node: FinanceFullData, item?: HTMLElement) {
    this.fisrtChildNode = node.children[0];
    this.secondChildNode = node.children[1];
    if (node.show === 'show') {
      node.show = null;
      if (this.fisrtChildNode && this.secondChildNode) {
        this.fisrtChildNode.show = null;
        this.secondChildNode.show = null;
      } else {
        this.fisrtChildNode.children?.forEach((el) => {
          el.show = null;
          return el.children.map((item) => (item.show = null));
        });
        this.fisrtChildNode.show = null;
      }
    } else {
      this.animationState = 'show';
      node.show = this.animationState;
      this.fisrtChildNode.show = null;
      if (node.provider === 'График платежей') {
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      if (this.secondChildNode) {
        this.secondChildNode.show = null;
      }
    }
  }

  scrollBottom() {
    this.scrollDown.emit();
  }
}
