import { CartActionsTypes, CartActionsUnion } from '@Mesh/store/cart/cart.actions';
import { RequestStatus } from '@Mesh/shared/enums/request-status.enum';
import { CartItem, CartItemType, MinimalCartRuleGroupedStatus, MinimalCartRules, OrderCompletedState } from '@Mesh/store/cart/cart.models';
import { Product, Stock } from '@Mesh/core/models/product';

export const cartFeatureKey = 'cart';

export interface CartState {
  cart: CartItem[];
  defaultCart: CartItem[];
  recommendedCart: CartItem[];
  cartProducts: Product[];
  loadCartStatus: RequestStatus;
  submitCartStatus: RequestStatus;
  cartStockMap: Record<number, Stock>; // product id, Stock
  orderCompletedState: OrderCompletedState[];
  closestDateLoading: boolean;
  nextDeliveryDate: string;
  minimalCartRules: MinimalCartRules;
  minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  changesRecommendedCart: boolean;
}

const initialState: CartState = {
  cart: [],
  defaultCart: [],
  recommendedCart: [],
  cartProducts: [],
  orderCompletedState: [],
  loadCartStatus: RequestStatus.NONE,
  submitCartStatus: RequestStatus.NONE,
  cartStockMap: {},
  closestDateLoading: false,
  nextDeliveryDate: null,
  minimalCartRules: null,
  minimalCartRulesGroupStatus: null,
  changesRecommendedCart: false,
};

export function cartReducer(state = initialState, action: CartActionsUnion): CartState {
  switch (action.type) {
    case CartActionsTypes.LoadCart:
      return {
        ...state,
        loadCartStatus: RequestStatus.SENT,
      };
    case CartActionsTypes.LoadCartSuccess:
      return {
        ...state,
        loadCartStatus: RequestStatus.SUCCESS,
        defaultCart: [...action.payload],
        cart: [...action.payload],
      };
    case CartActionsTypes.LoadCartFailure:
      return {
        ...state,
        loadCartStatus: RequestStatus.FAILURE,
      };
    case CartActionsTypes.LoadCartProductsSuccess:
      return {
        ...state,
        cartProducts: action.payload,
      };
    case CartActionsTypes.SubmitCart:
      return {
        ...state,
        submitCartStatus: RequestStatus.SENT,
      };
    case CartActionsTypes.SubmitCartSuccess:
      const orderCompletedState: any = [];
      action.payload.orders.forEach((order) => {
        const items = [];
        let totalAmount = 0;
        order.carts.forEach((item) => {
          items.push({
            name: item.materialId.toString(),
            amount: item.quantity,
            unit: item.unit,
          });
          totalAmount += item.quantity;
        });
        orderCompletedState.push({
          orderNumber: order.orderNumber,
          items: items,
          type: order.type,
          delayedTo: order.delayedTo,
          totalPrice: order.orderSum,
          totalAmount: totalAmount,
          distributorName: order.distributorName,
        });
      });

      orderCompletedState.forEach((order) => {
        order.items.forEach((item) => {
          const curr = action.payload.cart.find((itm) => itm.materialId === parseInt(item.name, 10));
          item.name = curr.name;
          order.salesOrgSapId = curr.salesOrgSapId;
          order.distributorCode = curr.distributorCode;
        });
      });

      return {
        ...state,
        submitCartStatus: RequestStatus.SUCCESS,
        orderCompletedState,
        cart: [],
        recommendedCart: [],
        defaultCart: [],
        // orderCompletedState: { ...state.orderCompletedState, orderNumber: action.payload.orderNumber },
      };
    case CartActionsTypes.SubmitCartFailure:
      return {
        ...state,
        submitCartStatus: RequestStatus.FAILURE,
        orderCompletedState: undefined,
      };
    case CartActionsTypes.UpdateCartSuccess:
      const tempUpdateCart = [...state.cart];
      const updatedItemIndex = tempUpdateCart.findIndex(
        // (el) => el.materialId === action.cartItem.materialId && el.unit === action.cartItem.unit

        (el) =>
          el.materialId === action.cartItem.materialId &&
          el.unit === action.cartItem.unit &&
          el.distributorCode === action.cartItem.distributorCode &&
          el.distributorName === action.cartItem.distributorName &&
          el.salesOrgSapId === action.cartItem.salesOrgSapId &&
          el.ndsPrice === action.cartItem.ndsPrice
      );
      if (updatedItemIndex === -1) {
        tempUpdateCart.push({ ...action.cartItem });
      } else {
        if (action.cartItem.quantity) {
          tempUpdateCart[updatedItemIndex] = { ...action.cartItem };
        } else {
          tempUpdateCart.splice(updatedItemIndex, 1);
        }
      }
      const elementUpdateRecommendCart = (state.recommendedCart || []).find(
        (el) => el.materialId === action.cartItem.materialId && el.unit === action.cartItem.unit
      );
      return {
        ...state,
        defaultCart: [...tempUpdateCart].filter((item) => item.type.indexOf(CartItemType.RECOMMENDED) !== -1),
        cart: [...tempUpdateCart],
        changesRecommendedCart: elementUpdateRecommendCart && elementUpdateRecommendCart.quantity > action.cartItem.quantity && true,
      };
    case CartActionsTypes.DeleteProductSuccess:
      const tempRemoveCart = [...state.cart];
      const indexToRemove = tempRemoveCart.findIndex(
        (el) => el.materialId === action.payload.materialId && el.unit === action.payload.unit
      );
      tempRemoveCart.splice(indexToRemove, 1);
      const elementRemoveRecommendCart = (state.recommendedCart || []).find(
        (el) => el.materialId === action.payload.materialId && el.unit === action.payload.unit
      );
      return {
        ...state,
        defaultCart: [...tempUpdateCart].filter((item) => item.type.indexOf(CartItemType.RECOMMENDED) !== -1),
        cart: [...tempUpdateCart],
        changesRecommendedCart: elementRemoveRecommendCart && true,
      };
    case CartActionsTypes.SetStock:
      const obj = { ...state.cartStockMap };
      obj[action.payload.id] = action.payload.stock;
      return {
        ...state,
        cartStockMap: { ...obj },
      };
    case CartActionsTypes.PageProductsLoaded:
      const stockObj: Record<number, Stock> = {};
      action.products
        .filter((el) => !!el)
        .forEach((product) => {
          if (!stockObj[product.id]) {
            stockObj[product.id] = product.stock.sort((a, b) =>
              a.baseQuantUnit !== b.baseQuantUnit ? (a.baseQuantUnit < b.baseQuantUnit ? -1 : 1) : 0
            )[0];
          }
        });
      return {
        ...state,
        cartStockMap: stockObj,
      };
    case CartActionsTypes.ClearOnOrderCompletedState:
      return {
        ...state,
        orderCompletedState: undefined,
        submitCartStatus: RequestStatus.NONE,
      };
    case CartActionsTypes.LoadDeliveryDate:
      return {
        ...state,
        closestDateLoading: true,
      };
    case CartActionsTypes.LoadDeliveryDateSuccess:
      const ordCompSt = (state.orderCompletedState ? [...state.orderCompletedState] : []).map((order) => {
        order.closestDate = action.payload.date;
        return order;
      });
      return {
        ...state,
        closestDateLoading: false,
        nextDeliveryDate: action.payload.date,
        orderCompletedState: ordCompSt,
        // orderCompletedState: state.orderCompletedState ? { ...state.orderCompletedState, closestDate: action.payload.date } : undefined,
      };
      return state;
    case CartActionsTypes.LoadDeliveryDateFailure:
      return {
        ...state,
        closestDateLoading: false,
      };
    case CartActionsTypes.SetOrderSubmitState:
      const orderCompSt = [...state.orderCompletedState];

      orderCompSt.forEach((order) => {
        order.items.forEach((item) => {
          const curr = action.payload.find((itm) => itm.materialId === parseInt(item.name, 10));
          item.name = curr.name;
          order.salesOrgSapId = curr.salesOrgSapId;
        });
      });

      return {
        ...state,
        // orderCompletedState: { ...state.orderCompletedState, ...action.payload },
        orderCompletedState: orderCompSt,
      };
    case CartActionsTypes.LoadMinimalCartRulesByCategorySuccess:
      return {
        ...state,
        minimalCartRules:
          action.payload && action.payload.length && action.payload[0].currentAmount < action.payload[0].amount && action.payload[0],
      };

    case CartActionsTypes.ResetMinimalCartRulesByCategory:
      return {
        ...state,
        minimalCartRules: null,
      };

    case CartActionsTypes.LoadMinimalCartRuleGroupedStatusSuccess:
      return {
        ...state,
        minimalCartRulesGroupStatus: action.payload,
      };

    case CartActionsTypes.LoadRecommendedOrderInCartSuccess:
      return {
        ...state,
        recommendedCart: [...action.payload],
        cart: [...action.payload, ...state.defaultCart],
      };

    case CartActionsTypes.UpdateRecommendedOrderInCart:
      return {
        ...state,
        cart: [...state.recommendedCart, ...state.defaultCart],
        changesRecommendedCart: false,
      };

    case CartActionsTypes.ResetCart:
      return {
        ...state,
        cart: [],
        defaultCart: [],
      };

    case CartActionsTypes.ResetRecommendedOrderInCart:
      return {
        ...state,
        recommendedCart: [],
        cart: [],
      };

    case CartActionsTypes.FillUpCartsMinimalLimitSuccess:
      return {
        ...state,
        defaultCart: [...action.payload],
        cart:
          state.recommendedCart && state.recommendedCart.length ? [...state.recommendedCart, ...state.defaultCart] : [...action.payload],
        changesRecommendedCart: false,
      };

    default:
      return state;
  }
}
