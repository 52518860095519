import { createSelector } from '@ngrx/store';
import { selectBonusState } from '@Mesh/store/app.state';
import { BonusState } from '@Mesh/store/bonus/bonus.state';
import { SalePlans } from '@Mesh/core/models/product';
import { Plan } from '@Mesh/core/models/plan';
import { Bonus } from '@Mesh/core/models/bonus';

export const getClientBonus = createSelector(selectBonusState, (state: BonusState) => state.clientBonusData);
export const getClientBonusPredict = createSelector(selectBonusState, (state: BonusState) => state.clientBonusPredict);
export const getClientBonusPredictTotalAmount = createSelector(
  selectBonusState,
  (state: BonusState) => state.clientBonusPredictTotalAmount
);
export const getClientsBonusByPeriod = createSelector(selectBonusState, (state: BonusState) => state.clientsBonusByPeriodData);
export const getActivePredictBonusId = createSelector(selectBonusState, (state) => state.activePredictBonusId);

export const getOutletBonuses = createSelector(selectBonusState, (state: BonusState) => state.outletBonusesData);
export const bonusesWithoutActive = createSelector(getOutletBonuses, getActivePredictBonusId, (data, activeBonusId) => {
  return data?.filter((el) => el?.planId !== activeBonusId && el?.planId !== undefined && el?.planId !== null) || [];
});
export const getBonusesByCategory = (category: string) =>
  createSelector(bonusesWithoutActive, (data: Bonus[]) => {
    return data.filter((el) => el.categoryName === category);
  });
export const getOutletBonusesSum = createSelector(selectBonusState, (state: BonusState) => state.outletBonusesSum);
export const getOutletBonusesPredict = createSelector(selectBonusState, (state: BonusState) => state.outletBonusesPredictData);
export const getOutletBonusesPredictSum = createSelector(selectBonusState, (state: BonusState) => state.outletBonusesPredictSum);
export const getOutletBonusPredict = createSelector(selectBonusState, (state: BonusState) => state.outletBonusPredictData);
export const getError = createSelector(selectBonusState, (state: BonusState) => state.error);

export const getPredictionData = createSelector(selectBonusState, (state) => state.outletBonusesPredictData);

export const getAwaitingSum = createSelector(selectBonusState, (state) => state.outletAwaitingSum);
export const getActiveBonus = createSelector(selectBonusState, (state) =>
  state?.outletBonusesData?.find((el) => el?.planId === state?.activeBonusId)
);
export const getActivePredictBonus = createSelector(getOutletBonusesPredict, getActivePredictBonusId, (data, activePredictBonusId) =>
  data?.find((el) => el?.planId === activePredictBonusId)
);
export const getActiveBonusId = createSelector(selectBonusState, (state) => state.activeBonusId);
export const getPlanShortName = (salePlan: SalePlans) =>
  createSelector(getOutletBonuses, getOutletBonusesPredict, (bonuses, predictBonuses) => {
    return (
      bonuses?.find((el) => el.planId === salePlan.planId)?.shortName ||
      predictBonuses?.find((el) => el.planId === salePlan.planId)?.shortName ||
      salePlan.planName
    );
  });
export const getPlans = createSelector(getOutletBonuses, (data) => {
  return data?.map((bonus) => ({ plan_id: bonus.planId, planName: bonus.planName, category: bonus.categoryName }));
});

export const getSelectedUserBonusBalance = createSelector(selectBonusState, (state: BonusState) => state.selectedUserBalance);
