import { Action } from '@ngrx/store';
import {
  CartItem,
  DeleteCartItem,
  MinimalCartRuleGroupedStatus,
  MinimalCartRules,
  MinimalCartRulesCriteria,
  OrderCompletedState,
  UpdateCartItem,
  OrderCompleted,
  CartItemDisplay,
} from '@Mesh/store/cart/cart.models';
import { Product, Stock } from '@Mesh/core/models/product';

export enum CartActionsTypes {
  LoadCart = '[Cart] Load cart',
  LoadCartSuccess = '[Cart] Load cart success',
  LoadCartFailure = '[Cart] Load cart failure',

  LoadCartProductsSuccess = '[Cart] Load cart products success',
  LoadCartProductsFailure = '[Cart] Load cart products failure',

  UpdateCart = '[Cart] Update cart',
  UpdateCartSuccess = '[Cart] Update cart success',
  UpdateCartFailure = '[Cart] Update cart failure',

  DeleteProduct = '[Cart] Delete product',
  DeleteProductSuccess = '[Cart] Delete product success',
  DeleteProductFailure = '[Cart] DeleteProductFailure',

  SubmitCart = '[Cart] SubmitCart',
  SubmitCartSuccess = '[Cart] SubmitCartSuccess',
  SubmitCartFailure = '[Cart] SubmitCartFailure',

  DropCart = '[Cart] DropCart',
  DropCartSuccess = '[Cart] DropCartSuccess',
  DropCartFailure = '[Cart] DropCartFailure',

  AddProduct = '[Cart] Add product',
  DecrementProduct = '[Cart] Decrement product',
  ChangeAmount = '[Cart] Change amount',

  SetStock = '[Cart] Set stock',

  PageProductsLoaded = '[Cart] Page products loaded',

  Empty = '[Cart] Empty action',

  ResetCart = '[Cart] ResetCart',

  IncrementProductInCart = '[Cart] Add product from cart',
  DecrementProductInCart = '[Cart] Decrement product in cart',

  ClearOnOrderCompletedState = '[Cart] Clear on delete state',

  LoadDeliveryDate = '[Cart] Load delivery date',
  LoadDeliveryDateSuccess = '[Cart] Load delivery date success',
  LoadDeliveryDateFailure = '[Cart] Load delivery date failure',

  SetOrderSubmitState = '[Cart] Set order submit state',

  LoadMinimalCartRulesByCategory = '[Cart] Load minimal cart rules by category',
  LoadMinimalCartRulesByCategorySuccess = '[Cart] Load minimal cart rules by category success',
  LoadMinimalCartRulesByCategoryFailure = '[Cart] Load minimal cart rules by category failure',
  ResetMinimalCartRulesByCategory = '[Cart] Reset minimal cart rules by category',

  LoadMinimalCartRuleGroupedStatus = '[Cart] Load minimal cart rule grouped status',
  LoadMinimalCartRuleGroupedStatusSuccess = '[Cart] Load minimal cart rule grouped status success',
  LoadMinimalCartRuleGroupedStatusFailure = '[Cart] Load minimal cart rule grouped status failure',

  UpdateRecommendedOrderInCart = '[Cart] Update recommended order cart',
  UpdateRecommendedOrderInCartSuccess = '[Cart] Update recommended order cart success',
  UpdateRecommendedOrderInCartFailure = '[Cart] Update recommended order cart failure',

  LoadRecommendedOrderInCart = '[Cart] Load recommended order cart',
  LoadRecommendedOrderInCartSuccess = '[Cart] Load recommended order cart success',
  LoadRecommendedOrderInCartFailure = '[Cart] Load recommended order cart failure',
  ResetRecommendedOrderInCart = '[Cart] Reset recommended order cart',

  FillUpCartsMinimalLimit = '[Cart] Fill up carts to minimal limit',
  FillUpCartsMinimalLimitSuccess = '[Cart] Fill up carts to minimal limit success',
  FillUpCartsMinimalLimitFailure = '[Cart] Fill up carts to minimal limit failure',
  ResetCartsMinimalLimit = '[Cart] Reset carts to minimal limit',
}

export class LoadCart implements Action {
  readonly type = CartActionsTypes.LoadCart;
}
export class LoadCartSuccess implements Action {
  readonly type = CartActionsTypes.LoadCartSuccess;

  constructor(public payload: CartItem[]) {}
}
export class LoadCartFailure implements Action {
  readonly type = CartActionsTypes.LoadCartFailure;
}

export class LoadCartProductsSuccess implements Action {
  readonly type = CartActionsTypes.LoadCartProductsSuccess;

  constructor(public payload: Product[]) {}
}
export class LoadCartProductsFailure implements Action {
  readonly type = CartActionsTypes.LoadCartProductsFailure;
}

export class UpdateCart implements Action {
  readonly type = CartActionsTypes.UpdateCart;

  constructor(public body: UpdateCartItem) {}
}
export class UpdateCartSuccess implements Action {
  readonly type = CartActionsTypes.UpdateCartSuccess;

  constructor(public cartItem: CartItem) {}
}
export class UpdateCartFailure implements Action {
  readonly type = CartActionsTypes.UpdateCartFailure;
}

export class DeleteProduct implements Action {
  readonly type = CartActionsTypes.DeleteProduct;

  constructor(public cartItem: DeleteCartItem) {}
}
export class DeleteProductSuccess implements Action {
  readonly type = CartActionsTypes.DeleteProductSuccess;

  constructor(public payload: { materialId: number; unit: string }) {}
}
export class DeleteProductFailure implements Action {
  readonly type = CartActionsTypes.DeleteProductFailure;
}

export class SubmitCart implements Action {
  readonly type = CartActionsTypes.SubmitCart;
  constructor(public payload?: { recommendedOrderId: number }) {}
}
export class SubmitCartSuccess implements Action {
  readonly type = CartActionsTypes.SubmitCartSuccess;

  constructor(public payload: { orders: OrderCompleted[]; cart: CartItemDisplay[] }) {} //public payload: { orderNumber: string }
}
export class SubmitCartFailure implements Action {
  readonly type = CartActionsTypes.SubmitCartFailure;
}

export class DropCart implements Action {
  readonly type = CartActionsTypes.DropCart;
}
export class DropCartSuccess implements Action {
  readonly type = CartActionsTypes.DropCartSuccess;
}
export class DropCartFailure implements Action {
  readonly type = CartActionsTypes.DropCartFailure;
}

export class ResetCart implements Action {
  readonly type = CartActionsTypes.ResetCart;
  constructor() {}
}
export class AddProduct implements Action {
  readonly type = CartActionsTypes.AddProduct;

  constructor(public payload: { product: { id: number; materialId: number } }) {}
}
export class DecrementProduct implements Action {
  readonly type = CartActionsTypes.DecrementProduct;

  constructor(public payload: { product: { id: number; materialId: number } }) {}
}
export class ChangeAmount implements Action {
  readonly type = CartActionsTypes.ChangeAmount;

  constructor(public payload: { product: { id: number; materialId: number }; amount: number }) {}
}

export class IncrementProductInCart implements Action {
  readonly type = CartActionsTypes.IncrementProductInCart;

  constructor(public payload: { id: number; materialId: number; unit: string; distributorName: string }) {}
}
export class DecrementProductInCart implements Action {
  readonly type = CartActionsTypes.DecrementProductInCart;

  constructor(public payload: { id: number; materialId: number; unit: string; distributorName: string }) {}
}

export class SetStock implements Action {
  readonly type = CartActionsTypes.SetStock;

  constructor(public payload: { id: number; stock: Stock }) {}
}

export class PageProductsLoaded implements Action {
  readonly type = CartActionsTypes.PageProductsLoaded;

  constructor(public products: Product[]) {}
}

export class ClearOnOrderCompletedState implements Action {
  readonly type = CartActionsTypes.ClearOnOrderCompletedState;
}

export class LoadDeliveryDate implements Action {
  readonly type = CartActionsTypes.LoadDeliveryDate;
}
export class LoadDeliveryDateSuccess implements Action {
  readonly type = CartActionsTypes.LoadDeliveryDateSuccess;

  constructor(public payload: { date: string }) {}
}
export class LoadDeliveryDateFailure implements Action {
  readonly type = CartActionsTypes.LoadDeliveryDateFailure;
}

export class SetOrderSubmitState implements Action {
  readonly type = CartActionsTypes.SetOrderSubmitState;

  constructor(public payload: CartItemDisplay[]) {} //OrderCompletedState
}

export class EmptyAction implements Action {
  readonly type = CartActionsTypes.Empty;
}

export class LoadMinimalCartRulesByCategory implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRulesByCategory;

  constructor(public payload?: { materialGroupNameId: string }) {}
}
export class LoadMinimalCartRulesByCategorySuccess implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRulesByCategorySuccess;

  constructor(public payload: MinimalCartRules[]) {}
}
export class LoadMinimalCartRulesByCategoryFailure implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRulesByCategoryFailure;
}

export class ResetMinimalCartRulesByCategory implements Action {
  readonly type = CartActionsTypes.ResetMinimalCartRulesByCategory;
}

export class LoadMinimalCartRuleGroupedStatus implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRuleGroupedStatus;
  constructor() {}
}
export class LoadMinimalCartRuleGroupedStatusSuccess implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRuleGroupedStatusSuccess;

  constructor(public payload: MinimalCartRuleGroupedStatus) {}
}
export class LoadMinimalCartRuleGroupedStatusFailure implements Action {
  readonly type = CartActionsTypes.LoadMinimalCartRuleGroupedStatusFailure;
}

export class UpdateRecommendedOrderInCart implements Action {
  readonly type = CartActionsTypes.UpdateRecommendedOrderInCart;
  constructor(public payload: { recommendedOrderId: number }) {}
}
export class UpdateRecommendedOrderInCartSuccess implements Action {
  readonly type = CartActionsTypes.UpdateRecommendedOrderInCartSuccess;

  constructor(public payload: CartItem[]) {}
}
export class UpdateRecommendedOrderInCartFailure implements Action {
  readonly type = CartActionsTypes.UpdateRecommendedOrderInCartFailure;
}
export class LoadRecommendedOrderInCart implements Action {
  readonly type = CartActionsTypes.LoadRecommendedOrderInCart;
  constructor(public payload: { recommendedOrderId: number }) {}
}
export class LoadRecommendedOrderInCartSuccess implements Action {
  readonly type = CartActionsTypes.LoadRecommendedOrderInCartSuccess;

  constructor(public payload: CartItem[]) {}
}
export class LoadRecommendedOrderInCartFailure implements Action {
  readonly type = CartActionsTypes.LoadRecommendedOrderInCartFailure;
}
export class ResetRecommendedOrderInCart implements Action {
  readonly type = CartActionsTypes.ResetRecommendedOrderInCart;
}

export class FillUpCartsMinimalLimit implements Action {
  readonly type = CartActionsTypes.FillUpCartsMinimalLimit;

  constructor() {}
}
export class FillUpCartsMinimalLimitSuccess implements Action {
  readonly type = CartActionsTypes.FillUpCartsMinimalLimitSuccess;

  constructor(public payload: any) {}
}
export class FillUpCartsMinimalLimitFailure implements Action {
  readonly type = CartActionsTypes.FillUpCartsMinimalLimitFailure;
}

export type CartActionsUnion =
  | LoadCart
  | LoadCartSuccess
  | LoadCartFailure
  | LoadCartProductsSuccess
  | LoadCartProductsFailure
  | UpdateCart
  | UpdateCartSuccess
  | UpdateCartFailure
  | DeleteProduct
  | DeleteProductSuccess
  | DeleteProductFailure
  | SubmitCart
  | SubmitCartSuccess
  | SubmitCartFailure
  | DropCart
  | DropCartSuccess
  | DropCartFailure
  | AddProduct
  | DecrementProduct
  | ChangeAmount
  | IncrementProductInCart
  | DecrementProductInCart
  | SetStock
  | PageProductsLoaded
  | EmptyAction
  | ResetCart
  | ClearOnOrderCompletedState
  | LoadDeliveryDate
  | LoadDeliveryDateSuccess
  | LoadDeliveryDateFailure
  | SetOrderSubmitState
  | LoadMinimalCartRulesByCategory
  | LoadMinimalCartRulesByCategorySuccess
  | LoadMinimalCartRulesByCategoryFailure
  | ResetMinimalCartRulesByCategory
  | LoadMinimalCartRuleGroupedStatus
  | LoadMinimalCartRuleGroupedStatusSuccess
  | LoadMinimalCartRuleGroupedStatusFailure
  | UpdateRecommendedOrderInCart
  | UpdateRecommendedOrderInCartSuccess
  | UpdateRecommendedOrderInCartFailure
  | LoadRecommendedOrderInCart
  | LoadRecommendedOrderInCartSuccess
  | LoadRecommendedOrderInCartFailure
  | ResetRecommendedOrderInCart
  | FillUpCartsMinimalLimit
  | FillUpCartsMinimalLimitSuccess
  | FillUpCartsMinimalLimitFailure;
