import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskSalesAgentViewComponent } from './task-sales-agent-view/task-sales-agent-view.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tasks-sales-agent',
  templateUrl: './tasks-sales-agent.component.html',
  styleUrls: ['./tasks-sales-agent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSalesAgentComponent implements OnInit, OnDestroy {
  showCalendar: boolean;
  modalRef: BsModalRef;
  protected unsubscribe$ = new Subject();
  constructor(private modalService: BsModalService, private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onToggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }

  onSelectDateCalendar(event): void {
    this.showCalendar = false;
  }

  onOpenTaskView(): void {
    this.modalRef = this.modalService.show(TaskSalesAgentViewComponent, {
      class: 'modal-dialog-right',
      animated: false,
    });
    this.modalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedModal();
    });
  }

  onOpenTaskCreate(): void {}

  onClosedModal(): void {
    this.modalRef?.hide();
  }
}
