import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  SkipSelf,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { pagesToggleService } from '../../services/toggler.service';
import { Notifications, NotService } from '@Mesh/core/services/not.service';

declare var pg: any;

@Component({
  selector: 'pg-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: {
    class: 'page-sidebar',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  pin: boolean = false;
  drawer: boolean = false;
  sidebar;
  timer;
  @HostBinding('style.transform')
  style: string;

  private sideBarWidth = 280;
  private sideBarWidthCondensed = 280 - 70;

  @ContentChild('sideBarOverlay') sideBarOverlay: TemplateRef<void>;
  @ContentChild('sideBarHeader') sideBarHeader: TemplateRef<void>;
  @ContentChild('menuItems') menuItems: TemplateRef<void>;

  constructor(
    private appSidebar: ElementRef,
    private toggler: pagesToggleService,
    private notificationService: NotService,
    @SkipSelf() private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      this.toggler.sideBarToggle.subscribe((toggle) => {
        this.toggleMobile(toggle);
      })
    );
    this.subscriptions.push(
      this.toggler.pageContainerHover.subscribe((message) => {
        this.closeSideBar();
      })
    );
    this.subscriptions.push(
      this.toggler.menuDrawer.subscribe((message) => {
        this.toggleDrawer();
      })
    );
    this.mobileSidebar = false;
  }

  ngOnInit() {}

  ngOnDestroy() {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    clearTimeout(this.timer);
    this.cdr.detach();
  }

  @HostBinding('class.visible') mobileSidebar: boolean;

  @HostListener('mouseenter', ['$event'])
  @HostListener('click', ['$event'])
  openSideBar() {
    this.notificationService.emit(Notifications.MenuOpened, true);
    if (pg.isVisibleSm() || pg.isVisibleXs()) return false;
    if (this.pin) return false;

    this.style = 'translate3d(' + this.sideBarWidthCondensed + 'px, 0,0)';
    pg.addClass(document.body, 'sidebar-visible');

    this.cdr.detectChanges();
  }

  closeSideBar() {
    if (pg.isVisibleSm() || pg.isVisibleXs()) return false;
    if (this.pin) return false;

    this.notificationService.emit(Notifications.MenuOpened, false);

    this.style = 'translate3d(0,0,0)';
    pg.removeClass(document.body, 'sidebar-visible');

    //this.drawer = false;

    this.cdr.detectChanges();
  }

  toggleMenuPin() {
    if (this.pin) this.pin = false;
    else this.pin = true;
  }

  toggleDrawer() {
    if (this.drawer) this.drawer = false;
    else this.drawer = true;

    this.cdr.detectChanges();
  }

  toggleMobile(toggle: boolean) {
    this.notificationService.emit(Notifications.MenuOpened, toggle);
    clearTimeout(this.timer);
    if (toggle) {
      this.mobileSidebar = toggle;
      this.cdr.detectChanges();
    } else {
      this.timer = setTimeout(() => {
        this.mobileSidebar = toggle;
        this.cdr.detectChanges();
      }, 400);
    }
  }
}
