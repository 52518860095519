import { Action } from '@ngrx/store';
import { ExistPhoneNumber, Login as LoginModel, RegisterPhone, RegConfirm, Authorized, Entity } from '@Mesh/core/models/login';
import { IUserInfo, IUserInfoResponse, User } from '@Mesh/core/models/user';
import { PostIdentifier, PostRegVerify, PostRequestSignUp } from '@Mesh/core/models/request/post-auth';
import { PostApplication } from '@Mesh/core/models/request/post-application';
import { SetPasswordActionBody, SetPasswordRequestBody, VerifyUpdatePasswordRequestBody } from '@Mesh/core/models/request/password-models';
import { Client } from '@Mesh/core/models/client';
import {
  Participant,
  ParticipantRequestPost,
  ParticipantRequestPostConfirm,
  ParticipantResponsePostConfirm,
  ParticipantRequestGet,
} from '@Mesh/core/models/participant';

export enum LoginActionsTypes {
  CheckExistPhoneNumber = '[Check]',
  CheckExistPhoneNumberSuccess = '[Check] Success',
  CheckExistPhoneNumberError = '[Check] Error',
  RegisterPhoneNumber = '[Register]',
  RegisterPhoneNumberSuccess = '[Register] Success',
  RegisterPhoneNumberError = '[Register] Error',
  ConfirmRegister = '[Confirm]',
  ConfirmRegisterSuccess = '[Confirm] Success',
  ConfirmRegisterError = '[Confirm] Error',
  AcceptCode = '[Code]',
  AcceptCodeSuccess = '[Code] Success',
  AcceptCodeError = '[Code] Error',
  Signup = '[Signup]',
  SignupSuccess = '[Signup] SUCCESS',
  SignupError = '[Signup] ERROR',
  AuthCancel = '[LOGIN] clear all date',
  Login = '[LOGIN]',
  LoginSuccess = '[LOGIN] LOAD SUCCESS',
  LoginError = '[LOGIN] LOAD ERROR',
  Logout = '[LOGIN] LOGOUT',
  ApplicationRequest = '[APPLICATION] Request',
  ApplicationSuccess = '[APPLICATION] LOAD SUCCESS',
  ApplicationError = '[APPLICATION] LOAD ERROR',
  Empty = '[APPLICATION] Empty',

  SetClient = '[Set] Client',
  SetClientSuccess = '[Set] Client success',
  SetClientFailure = '[Set] Client failure',

  RequestUpdatePassword = '[LOGIN] Request Update Password',
  RequestUpdatePasswordSuccess = '[LOGIN] Request Update Password success',
  RequestUpdatePasswordFailure = '[LOGIN] Request Update Password failure',

  VerifyUpdatePassword = '[LOGIN] Verify update password',
  VerifyUpdatePasswordSuccess = '[LOGIN] Verify update password success',
  VerifyUpdatePasswordFailure = '[LOGIN] Verify update password failure',

  SetNewPasswordRequest = '[LOGIN] Set new password request',
  SetNewPasswordRequestSuccess = '[LOGIN] Set new password request success',
  SetNewPasswordRequestFailure = '[LOGIN] Set new password request failure',
  RequestUserUpdatePassword = '[LOGIN] Request user update password',
  RequestUserUpdatePasswordSuccess = '[LOGIN] Request user update password success',
  RequestUserUpdatePasswordFailure = '[LOGIN] Request user update password failure',

  UpdateLoginUserAvatar = '[LOGIN] Update avatar',

  GetUser = '[LOGIN] Get user',
  GetUserSuccess = '[LOGIN] Get user success',
  GetUserFailure = '[LOGIN] Get user failure',
  GetLegalEntities = '[Get] Entities',
  GetLegalEntitiesResponse = '[Get] Entities response',
  GetLegalEntitiesNext = '[Next] Continue',

  ResetIdentifierParticipant = '[LOGIN] reset indentifier participant',

  SendSmsCodeParticipant = '[LOGIN] send sms code participant',
  SendSmsCodeParticipantSuccess = '[LOGIN] send sms code participant success',
  SendSmsCodeParticipantFailure = '[LOGIN] send sms code participant failure',

  CreateParticipant = '[LOGIN] create participant',
  CreateParticipantSuccess = '[LOGIN] create participant success',
  CreateParticipantFailure = '[LOGIN] create participant failure',
}

export class SetClient implements Action {
  readonly type = LoginActionsTypes.SetClient;

  constructor(public payload: { clientSapId: number }) {}
}

export class SetClientNext implements Action {
  readonly type = LoginActionsTypes.GetLegalEntitiesNext;

  constructor() {}
}

export class SetClientSuccess implements Action {
  readonly type = LoginActionsTypes.SetClientSuccess;

  constructor(public payload: { token: string; user: User }) {}
}

export class SetClientFailure implements Action {
  readonly type = LoginActionsTypes.SetClientFailure;

  constructor(public payload: { error: any }) {}
}

export class LoginRequest implements Action {
  readonly type = LoginActionsTypes.Login;

  constructor(public payload: LoginModel) {}
}

export class EmptyAction implements Action {
  readonly type = LoginActionsTypes.Empty;
}

export class AuthCancel implements Action {
  readonly type = LoginActionsTypes.AuthCancel;
}

export class LoginSuccess implements Action {
  readonly type = LoginActionsTypes.LoginSuccess;

  constructor(public payload: { token: string; user: User }) {}
}

export class LoginError implements Action {
  readonly type = LoginActionsTypes.LoginError;

  constructor(public error: { errors: any[] }) {}
}

export class LogoutRequest implements Action {
  readonly type = LoginActionsTypes.Logout;
}

export class GetLegalEntities implements Action {
  readonly type = LoginActionsTypes.GetLegalEntities;
}

export class GetLegalEntitiesResponse implements Action {
  readonly type = LoginActionsTypes.GetLegalEntitiesResponse;

  constructor(public payload: Array<Entity>) {}
}

export class CheckExistPhoneNumber implements Action {
  readonly type = LoginActionsTypes.CheckExistPhoneNumber;

  constructor(public payload: ExistPhoneNumber) {}
}

export class CheckExistPhoneNumberSuccess implements Action {
  readonly type = LoginActionsTypes.CheckExistPhoneNumberSuccess;

  constructor(public payload: Authorized) {}
}

export class CheckExistPhoneNumberError implements Action {
  readonly type = LoginActionsTypes.CheckExistPhoneNumberError;

  constructor(public error: { errors: any[] }) {}
}

export class RegisterPhoneNumber implements Action {
  readonly type = LoginActionsTypes.RegisterPhoneNumber;

  constructor(public payload: ExistPhoneNumber) {}
}

export class RegisterPhoneNumberSuccess implements Action {
  readonly type = LoginActionsTypes.RegisterPhoneNumberSuccess;

  constructor(public payload: RegisterPhone) {}
}

export class RegisterPhoneNumberError implements Action {
  readonly type = LoginActionsTypes.RegisterPhoneNumberError;

  constructor(public error: { errors: any[] }) {}
}

export class ConfirmRegister implements Action {
  readonly type = LoginActionsTypes.ConfirmRegister;

  constructor(public payload: PostIdentifier) {}
}

export class ConfirmRegisterSuccess implements Action {
  readonly type = LoginActionsTypes.ConfirmRegisterSuccess;

  constructor(public payload: RegConfirm) {}
}

export class ConfirmRegisterError implements Action {
  readonly type = LoginActionsTypes.ConfirmRegisterError;

  constructor(public error: { errors: any[] }) {}
}

export class AcceptCode implements Action {
  readonly type = LoginActionsTypes.AcceptCode;

  constructor(public payload: PostRegVerify) {}
}

export class AcceptCodeSuccess implements Action {
  readonly type = LoginActionsTypes.AcceptCodeSuccess;

  constructor(public payload: {}) {}
}

export class AcceptCodeError implements Action {
  readonly type = LoginActionsTypes.AcceptCodeError;

  constructor(public error: { errors: any[] }) {}
}

export class Signup implements Action {
  readonly type = LoginActionsTypes.Signup;

  constructor(public payload: PostRequestSignUp) {}
}

export class SignupSuccess implements Action {
  readonly type = LoginActionsTypes.SignupSuccess;

  constructor(public payload: IUserInfo) {}
}

export class SignupError implements Action {
  readonly type = LoginActionsTypes.SignupError;

  constructor(public error: { errors: any[] }) {}
}

export class ApplicationRequest implements Action {
  readonly type = LoginActionsTypes.ApplicationRequest;

  constructor(public payload: PostApplication) {}
}

export class ApplicationSuccess implements Action {
  readonly type = LoginActionsTypes.ApplicationSuccess;

  constructor(public payload: { applicationStatus: boolean }) {}
}

export class ApplicationError implements Action {
  readonly type = LoginActionsTypes.ApplicationError;

  constructor(public error: { errors: any[] }) {}
}

export class RequestUpdatePassword implements Action {
  readonly type = LoginActionsTypes.RequestUpdatePassword;

  constructor(public payload: { phoneNumber: string }) {}
}

export class RequestUserUpdatePassword implements Action {
  readonly type = LoginActionsTypes.RequestUserUpdatePassword;

  constructor(public payload: { phoneNumber: string; password: string }) {}
}

export class RequestUserUpdatePasswordSuccess implements Action {
  readonly type = LoginActionsTypes.RequestUserUpdatePasswordSuccess;

  constructor(public payload: { identifier: number; password: string }) {}
}

export class RequestUserUpdatePasswordFailure implements Action {
  readonly type = LoginActionsTypes.RequestUserUpdatePasswordFailure;

  constructor(public payload: { error: any }) {}
}

export class RequestUpdatePasswordSuccess implements Action {
  readonly type = LoginActionsTypes.RequestUpdatePasswordSuccess;

  constructor(public payload: { identifier: number }) {}
}

export class RequestUpdatePasswordFailure implements Action {
  readonly type = LoginActionsTypes.RequestUpdatePasswordFailure;

  constructor(public payload: { error: any }) {}
}

export class VerifyUpdatePassword implements Action {
  readonly type = LoginActionsTypes.VerifyUpdatePassword;

  constructor(public payload: { code: string }) {}
}

export class VerifyUpdatePasswordSuccess implements Action {
  readonly type = LoginActionsTypes.VerifyUpdatePasswordSuccess;
}

export class VerifyUpdatePasswordFailure implements Action {
  readonly type = LoginActionsTypes.VerifyUpdatePasswordFailure;

  constructor(public payload: { error: any }) {}
}

export class SetNewPasswordRequest implements Action {
  readonly type = LoginActionsTypes.SetNewPasswordRequest;

  constructor(public payload: SetPasswordActionBody) {}
}

export class SetNewPasswordRequestSuccess implements Action {
  readonly type = LoginActionsTypes.SetNewPasswordRequestSuccess;

  constructor(public payload: IUserInfo) {}
}

export class SetNewPasswordRequestFailure implements Action {
  readonly type = LoginActionsTypes.SetNewPasswordRequestFailure;

  constructor(public payload: { error: any }) {}
}

export class UpdateLoginUserAvatar implements Action {
  readonly type = LoginActionsTypes.UpdateLoginUserAvatar;

  constructor(public payload: { avatar: string }) {}
}

export class GetUser implements Action {
  readonly type = LoginActionsTypes.GetUser;
}

export class GetUserSuccess implements Action {
  readonly type = LoginActionsTypes.GetUserSuccess;

  constructor(public payload: { user: User }) {}
}

export class GetUserFailure implements Action {
  readonly type = LoginActionsTypes.GetUserFailure;

  constructor(public payload: { error: any }) {}
}

export class SendSmsCodeParticipant implements Action {
  readonly type = LoginActionsTypes.SendSmsCodeParticipant;

  constructor(public payload: ParticipantRequestPostConfirm) {}
}

export class SendSmsCodeParticipantSuccess implements Action {
  readonly type = LoginActionsTypes.SendSmsCodeParticipantSuccess;

  constructor(public payload: ParticipantResponsePostConfirm) {}
}

export class SendSmsCodeParticipantFailure implements Action {
  readonly type = LoginActionsTypes.SendSmsCodeParticipantFailure;

  constructor(public payload: { error: any }) {}
}

export class CreateParticipant implements Action {
  readonly type = LoginActionsTypes.CreateParticipant;

  constructor(public payload: ParticipantRequestPost) {}
}

export class CreateParticipantSuccess implements Action {
  readonly type = LoginActionsTypes.CreateParticipantSuccess;

  constructor(public payload: Participant) {}
}

export class CreateParticipantFailure implements Action {
  readonly type = LoginActionsTypes.CreateParticipantFailure;

  constructor(public payload: { error: any }) {}
}

export class ResetIdentifierParticipant implements Action {
  readonly type = LoginActionsTypes.ResetIdentifierParticipant;

  constructor() {}
}

export type LoginActionsUnion =
  | AuthCancel
  | LoginRequest
  | LoginSuccess
  | LoginError
  | LogoutRequest
  | GetLegalEntities
  | GetLegalEntitiesResponse
  | CheckExistPhoneNumber
  | CheckExistPhoneNumberSuccess
  | CheckExistPhoneNumberError
  | RegisterPhoneNumber
  | RegisterPhoneNumberSuccess
  | RegisterPhoneNumberError
  | ConfirmRegister
  | ConfirmRegisterSuccess
  | ConfirmRegisterError
  | AcceptCode
  | AcceptCodeSuccess
  | AcceptCodeError
  | Signup
  | SignupSuccess
  | SignupError
  | ApplicationRequest
  | ApplicationSuccess
  | ApplicationError
  | RequestUpdatePassword
  | RequestUpdatePasswordSuccess
  | RequestUpdatePasswordFailure
  | VerifyUpdatePassword
  | VerifyUpdatePasswordSuccess
  | VerifyUpdatePasswordFailure
  | SetNewPasswordRequest
  | SetNewPasswordRequestSuccess
  | SetNewPasswordRequestFailure
  | RequestUserUpdatePassword
  | RequestUserUpdatePasswordSuccess
  | RequestUserUpdatePasswordFailure
  | UpdateLoginUserAvatar
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | SetClient
  | SetClientSuccess
  | SetClientFailure
  | SendSmsCodeParticipant
  | SendSmsCodeParticipantSuccess
  | SendSmsCodeParticipantFailure
  | CreateParticipant
  | CreateParticipantSuccess
  | CreateParticipantFailure
  | ResetIdentifierParticipant;
