import { createSelector } from '@ngrx/store';

import { selectLoginState } from '../app.state';
import { LoginState } from './login.state';

export const loginSelectors = {
  selectAuth: createSelector(selectLoginState, (state: LoginState) => state),
  selectIsAuthenticated: createSelector(selectLoginState, (state: LoginState) => state.isAuthenticated),
  selectToken: createSelector(selectLoginState, (state: LoginState) => state.token),
  selectUser: createSelector(selectLoginState, (state: LoginState) => state.user),
  selectUserClient: createSelector(selectLoginState, (state: LoginState) => (state.user ? state.user.client : null)),
  selectUserId: createSelector(selectLoginState, (state) => state?.user?.id),
  selectError: createSelector(selectLoginState, (state: LoginState) => state.error),
  selectExistPhoneNumber: createSelector(selectLoginState, (state: LoginState) => state.canBeAuthorized),
  selectPhoneIdentifier: createSelector(selectLoginState, (state: LoginState) => state.identifier),
  selectParticipantIdentifier: createSelector(selectLoginState, (state: LoginState) => state.identifierParticipant),
  selectDocuments: createSelector(selectLoginState, (state: LoginState) => state.documents),
  selectShouldCreatePassword: createSelector(selectLoginState, (state: LoginState) => state.shouldCreatePassword),
  selectName: createSelector(selectLoginState, (state: LoginState) => state.name),
  selectApplicationStatus: createSelector(selectLoginState, (state: LoginState) => state.applicationStatus),
  selectAcceptCodeSuccess: createSelector(selectLoginState, (state: LoginState) => state.acceptCodeSuccess),
  selectLoading: createSelector(selectLoginState, (state: LoginState) => state.loading),
  selectUpdatePhoneIdentifier: createSelector(selectLoginState, (state: LoginState) => state.updatePasswordIdentifier),
  selectUpdatePasswordSmsVerifyStatus: createSelector(selectLoginState, (state) => state.updatePasswordSmsVerifyStatus),
  newUserPassword: createSelector(selectLoginState, (state) => state.newUserPassword),
  selectUpdatePasswordVerifyStatus: createSelector(selectLoginState, (state) => state.updatePasswordVerifyStatus),
  selectEntities: createSelector(selectLoginState, (state: LoginState) => (state.entities ? state.entities : null)),
  selectPasswordSetSuccess: createSelector(selectLoginState, (state: LoginState) => state.passwordSetSuccess),
};
