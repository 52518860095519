import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RootLayout } from '@Mesh/@pages/layouts';
import { Observable, Subject, Subscription } from 'rxjs';
import { User } from '@Mesh/core/models/user';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { pagesToggleService } from '../../services/toggler.service';
import { AuthenticationService, DataService } from '@Mesh/core/services';
import { BsDropdownDirective } from 'ngx-bootstrap';
import { NotService, Notifications } from '@Mesh/core/services/not.service';
import { map, takeUntil } from 'rxjs/operators';
import { ResizeHelper } from '@Mesh/shared/helpers/resize.helper';
import { CatalogHelper } from '@Mesh/shared/helpers/catalog.helper';
import { select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { BasketHelper } from '@Mesh/shared/helpers/basket.helper';
import { IMAGE_CLOUD_URL } from '@Env/environment';
import { LogoutRequest } from '@Mesh/store/login/login.actions';

@Component({
  selector: 'common-layout',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonComponent extends RootLayout implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('menuDropdown') menuDropdownRef: BsDropdownDirective;
  menuLinks = [
    {
      label: 'Главная',
      routerLink: '/',
      iconType: 'pg',
      iconName: 'home',
    },
    {
      label: 'Активные заказы',
      iconType: 'fa',
      iconName: 'shopping-cart',
      routerLink: '/active/order',
    },
    {
      label: 'Задания',
      iconType: 'pg',
      iconName: 'note',
      toggle: 'close',
      details: 0,
      routerLink: '/active/task',
    },
    {
      label: 'Каталог',
      iconType: 'fa',
      iconName: 'users',
      routerLink: '/catalog',
    },
    {
      label: 'Цели',
      iconType: 'fa',
      iconName: 'bullseye',
      routerLink: '/catalog/plan',
    },
    {
      label: 'История',
      iconType: 'fa',
      iconName: 'history',
      routerLink: '/history',
    },
    {
      label: 'Профиль',
      iconType: 'pg',
      iconName: 'layouts',
      routerLink: '/profile',
    },
  ];

  loading = false;
  title;
  secondTitle;
  hasMessages;
  headerBgc$: Observable<string>;
  bgc = 'bg-blue';
  _router: Router;
  imgUrl = IMAGE_CLOUD_URL;
  readonly userAvatar$ = this.store.select(loginSelectors.selectUser).pipe(
    map((user) => {
      return this.imgUrl + user.avatar;
    })
  );

  private _user: User;
  private _route: ActivatedRoute;
  private u$ = new Subject();

  private subsToggleSidebar: Subscription;
  private stateToggleSidebat: boolean = false;

  constructor(
    public toggler: pagesToggleService,
    protected router: Router,
    private route: ActivatedRoute,
    private notificationService: NotService,
    public cdr: ChangeDetectorRef,
    private dataService: DataService,
    public resize: ResizeHelper,
    public catalogHelper: CatalogHelper,
    private store: Store<AppState>,
    public basketHelper: BasketHelper,
    public authService: AuthenticationService
  ) {
    super(toggler, router, cdr);
    this._router = router;
    this._route = route;
    this.resize.recalcSize();

    this.subsToggleSidebar = this.toggler.sideBarToggle.subscribe((toggle) => {
      this.stateToggleSidebat = toggle;
    });
  }

  @HostListener('window:panleft', ['$event'])
  onPanLeft(e: PointerEvent): void {
    if (e.pointerType !== 'touch') return;
    if (this.stateToggleSidebat) {
      this.toggleMobileSidebar();
      document.body.style.overflow = 'hidden';
      setTimeout(() => {
        document.body.style.removeProperty('overflow');
      }, 300);
    }
  }

  get user() {
    return this._user;
  }

  logout() {
    this.store.dispatch(new LogoutRequest());
  }

  ngOnInit() {
    this.headerBgc$ = this.dataService.headerBgcSubject;
    this.subscription.add(
      this._router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.menuLinks = this.menuLinks.slice();
        }
      })
    );
  }

  @HostListener('window:resize', [])
  caller() {
    this.resize.recalcSize();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.onAvatarSubmit(file);
    }
  }

  onAvatarSubmit(file?) {
    const formData = new FormData();
    formData.append('avatar', file);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
    this.cdr.detach();
    this.u$.next();
    this.u$.unsubscribe();

    this.subsToggleSidebar.unsubscribe();
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.notificationService.subscribe(Notifications.MenuOpened, {
        next: (state) => {
          if (!state && this.menuDropdownRef) {
            this.menuDropdownRef.hide();
          }
          this.cdr.detectChanges();
        },
      })
    );

    this.subscription.add(this.store.pipe(select(loginSelectors.selectUser), takeUntil(this.u$)).subscribe(this.onUser.bind(this)));
  }

  onUser(user) {
    console.log(user);
    if (this._user?.id === user?.id) {
      return;
    }

    this.loading = false;
    this._user = user;

    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  get isDevMode() {
    return isDevMode();
  }

  toggleBasket() {
    this.basketHelper.basketListToggle(false, false);
  }
}
