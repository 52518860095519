import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksSalesAgentTagsComponent } from './tasks-sales-agent-tags.component';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  declarations: [TasksSalesAgentTagsComponent],
  exports: [TasksSalesAgentTagsComponent],
  imports: [CommonModule, SwiperModule],
})
export class TasksSalesAgentTagsModule {}
