import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { CartItemDisplay, CartItemType } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-recommended-cart-list',
  templateUrl: './recommended-cart-list.component.html',
  styleUrls: ['./recommended-cart-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedCartListComponent implements OnInit {
  @Input() cartList: any;
  @Input() numberOfOtherCart: number;
  @Input() numberOfRecommendCart: number;
  @Output() decrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() incrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  cartItemType = CartItemType;

  constructor() {}

  ngOnInit() {}

  incrementAmount(product: CartItemDisplay) {
    this.incrementedAmount.emit(product);
  }

  decrementAmount(product: CartItemDisplay) {
    this.decrementedAmount.emit(product);
  }

  deleteCartItem(product: CartItemDisplay) {
    this.deleted.emit(product);
  }
}
