import { HttpParams } from '@angular/common/http';

export function objectMap<T>(object, mapFn, arg: T): T {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key], key);
    return result;
  }, arg);
}

export function objectForEach(object, fn) {
  Object.keys(object).forEach((key) => {
    fn(object[key], key, object);
  });
}

export function getDescendantProp(obj, desc) {
  var arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
}

export function getRandomItem(array) {
  return array[Math.floor(Math.random() * array.length)];
}
export function uniqByKeepFirst(a, key) {
  const seen = new Set();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export const objectToParams = (obj: unknown): HttpParams => {
  const strObj: { [key: string]: string } = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== undefined && key !== null && value !== undefined && value !== null) {
      strObj[key] = value.toString();
    }
  });
  return new HttpParams({ fromObject: strObj });
};
