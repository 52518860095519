export interface CartItem {
  materialId: number;
  quantity: number;
  mrc: number;
  unit: string;
  price: number;
  ndsPrice: number;
  discountPrice: number;
  discountNdsPrice: number;
  salesOrgSapId?: string;
  distributorCode?: string;
  distributorName?: string;
  type?: string;
}

export interface UpdateCartItem {
  materialId: number;
  userId: number;
  quantity: number;
  mrc: number;
  unit: string;
  salesOrgSapId?: string;
  distributorCode?: string;
}

export interface DeleteCartItem {
  materialId: number;
  unit: string;
  mrc?: number;
  salesOrgSapId?: string;
  distributorCode?: string;
}

export interface DeleteCartItemCriteria {
  addressSapId: number;
  userId: number;
  materialId: number;
  unit: string;
  mrc?: number;
  salesOrgSapId?: string;
  distributorCode?: string;
}

export interface MinimalCartRulesCriteria {
  addressSapId?: number;
  materialGroupNameId?: string;
  userId?: number;
  recommendedOrderId?: number;
}

export interface LoadCartCriteria {
  addressSapId?: number;
  userId?: number;
  recommendedOrderId?: number;
}

export interface MinimalCartRules {
  id: number;
  amount: number;
  name: string;
  currentAmount: number;
  cartsIds: number[];
  categoryNames: any[];
}

export interface MinimalCartRuleGroupedStatus {
  status: CartRuleGroupedStatus;
  minimalCartRuleCartResponses: MinimalCartRules[];
}

export enum CartRuleGroupedStatus {
  NotAvailable = 'NOT_AVAILABLE',
  FullAvailable = 'FULL_AVAILABLE',
  PartialAvailable = 'PARTIAL_AVAILABLE',
}

export enum OrderCompletedStatusType {
  ORDER_NORMAL = 'NORMAL',
  ORDER_DELAYED = 'DELAYED',
}

export enum CartItemType {
  NORMAL = 'NORMAL',
  RECOMMENDED = 'RECOMMENDED',
}

export interface CartItemDisplay {
  materialId: number;
  image: string;
  name: string;
  mrc: number;
  pricePerUnit: number;
  unit: string;
  baseQuantUnit: number;
  baseUnit: string;
  baseNdsPrice: number;
  ndsPrice: number;
  price: number;
  discountPrice: number;
  discountNdsPrice: number;
  totalNdsPriceTag: number;
  totalDiscountNdsPriceTag: number;
  hasDiscount: boolean;
  quantity: number;
  orderAmount: number;
  id: number; // mean productId
  quantityMaterial: number;
  salesOrgSapId?: string;
  distributorCode?: string;
  distributorName?: string;
  type: string;
}

export interface OrderCompletedState {
  orderNumber?: string;
  items: { name: string; amount: number; unit: string }[];
  delayedTo: string;
  type: string;
  closestDate?: string;
  totalPrice: number;
  totalAmount: number;
  distributorName?: string;
  distributorCode?: string;
  salesOrgSapId?: string;
}

export interface OrderCompleted {
  orderNumber: string;
  orderSum: number;
  type: string;
  delayedTo: string;
  carts: CartItem[];
  distributorName: string;
}
