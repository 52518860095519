import { withLoadable } from '@Mesh/utils/loadable/with-loadable';
import { TaskActionsTypes, TaskActionsUnion } from '@Mesh/store/task/task.actions';
import { initialState, TaskState } from '@Mesh/store/task/task.state';

function baseTaskReducer(state = initialState, action: TaskActionsUnion): TaskState {
  switch (action.type) {
    case TaskActionsTypes.TaskLoadPageRequestFailure:
      return {
        ...state,
        error: action.error,
      };
    case TaskActionsTypes.TaskLoadSuccess:
      return {
        ...state,
        activeTasks: action.payload.tasks,
        totalPages: action.payload.totalPages,
      };
    case TaskActionsTypes.ModerationTaskLoadSuccess:
      return {
        ...state,
        moderationTasks: action.payload.tasks,
        totalPages: action.payload.totalPage,
      };
    case TaskActionsTypes.CompletedTaskLoadSuccess:
      return {
        ...state,
        completedTotalCount: action.payload.totalCount,
      };
    case TaskActionsTypes.TaskSetTotalCount:
      return {
        ...state,
        activeTotalCount: !action.payload.onModeration ? action.payload.totalCount : state.activeTotalCount,
        moderationTotalCount: action.payload.onModeration ? action.payload.totalCount : state.moderationTotalCount,
      };
    case TaskActionsTypes.TaskLoadError:
      return {
        ...state,
        error: action.error,
      };
    case TaskActionsTypes.HistoryTaskLoadSuccess:
      return {
        ...state,
        historyTasks: action.payload.tasks,
        historyTasksTotalCount: action.payload.tasksCount,
        totalPages: action.payload.totalPages,
        historyTasksCurrentPage: action.payload.currentPage,
      };
    case TaskActionsTypes.HistoryTaskLoadError:
      return {
        ...state,
        error: action.error,
      };
    case TaskActionsTypes.ResetHistoryTaskValue:
      return {
        ...state,
        historyTasks: [],
        errors: [],
        historyTasksTotalCount: 0,
        totalPages: 0,
        historyTasksCurrentPage: 0,
      };
    case TaskActionsTypes.ResetTaskValue:
      return {
        ...state,
        activeTasks: [],
        errors: [],
        activeTotalCount: 0,
        totalPages: 0,
        activeTasksCurrentPage: 0,
      };
    case TaskActionsTypes.ResetModerationTaskValue:
      return {
        ...state,
        moderationTasks: [],
        errors: [],
        moderationTotalCount: 0,
        totalPages: 0,
        moderationTasksCurrentPage: 0,
      };
    case TaskActionsTypes.TaskProgressLoadSuccess:
      return {
        ...state,
        currentTaskProgress: action.payload,
      };
    case TaskActionsTypes.TaskProgressLoadError:
      return {
        ...state,
        error: action.error,
      };
    case TaskActionsTypes.SetVisitTaskActive:
      return {
        ...state,
        visitTaskActive: action.task,
      };
    default:
      return state;
  }
}

export function taskReducer(state: TaskState, action: TaskActionsUnion): TaskState {
  return withLoadable(baseTaskReducer, {
    loadingActionType: TaskActionsTypes.TaskLoadRequest,
    successActionType: TaskActionsTypes.TaskLoadSuccess,
    errorActionType: TaskActionsTypes.TaskLoadError,
  })(state, action);
}
