export interface Bonus {
  currentBonus: number;
  nextStepBonus: number;
  executedPercent: number;
  nextStepPercent: number;
  requiredAmount: number;
  executedAmount: number;
  planName: string;
  planId: number;
  expectedBonus: string;
  strategy: Strategy;
  maxBonus: number;
  availablePercents: Percentage[];
  predictBonus: Bonus;
  shortName?: string;
  categoryName?: string;
  image?: string;
}

export interface Percentage {
  percent: number;
  bonus: number;
}

export enum Strategy {
  val = 'Val',
  orderAmount = 'OrderAmount',
  amount = 'Amount',
  top24Material = 'Top24Material',
  top24SaleVolume = 'Top24SaleVolume',
  driveList = 'DriveList',
}

export const TargetNamesRus = {
  [Strategy.val]: 'пач.',
  [Strategy.driveList]: 'пач.',
  [Strategy.amount]: 'руб.',
  [Strategy.orderAmount]: 'руб.',
  [Strategy.top24SaleVolume]: 'пач.',
  [Strategy.top24Material]: 'sku',
};

export type TEveryAmount = {
  planId: number | null;
  amount: number;
  ballStep: number;
  strategy: Strategy;
  expectedBonus: string;
  planName: string;
};

const everyAmount: TEveryAmount[] = [
  {
    planId: 3500000039,
    amount: 1000,
    ballStep: 50,
    strategy: Strategy.amount,
    expectedBonus: 'За каждые 1 000 ₽ получите - 50 Б',
    planName: 'Red Bull',
  },
  {
    planName: 'Зажигалки + Контрацептивы + Батареи',
    planId: null,
    amount: 2000,
    ballStep: 100,
    strategy: Strategy.orderAmount,
    expectedBonus: 'За каждые 2 000 ₽ получите - 100 Б',
  },
];

export const getMaxProgressByStrategy = (strategy: Strategy): number => {
  switch (strategy) {
    case Strategy.val:
    case Strategy.top24SaleVolume:
      return 120;
    case Strategy.driveList:
      return 100;
    default:
      return 100;
  }
};

export const getEveryPlan = (planId: number | null): TEveryAmount | undefined =>
  everyAmount.find((e) => (e.planId === planId && planId !== null) || (e.strategy === Strategy.orderAmount && planId === null));
