import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-tasks-sales-agent-v2-filter',
  templateUrl: './tasks-sales-agent-v2-filter.component.html',
  styleUrls: ['./tasks-sales-agent-v2-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSalesAgentV2FilterComponent implements OnInit {
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    spaceBetween: 3,
    slidesPerView: 'auto',
    freeMode: true,
    pagination: false,
    centeredSlides: false,
    mousewheel: true,
  };
  constructor() {}

  ngOnInit(): void {}
}
