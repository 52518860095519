import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import moment from 'moment';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-tasks-sales-agent-filter-week',
  templateUrl: './tasks-sales-agent-filter-week.component.html',
  styleUrls: ['./tasks-sales-agent-filter-week.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSalesAgentFilterWeekComponent implements OnInit {
  swiperConfig: SwiperConfigInterface = {
    init: true,
    observer: true,
    direction: 'horizontal',
    initialSlide: 0,
    spaceBetween: 3,
    slidesPerView: 'auto',
    freeMode: true,
    pagination: false,
    centeredSlides: false,
  };
  currentDay = new Date();
  stagger = true;
  dates = [];
  selectedDate = moment().startOf('day').format();
  weekOfYear: string;

  constructor() {
    this.changeDisplayedWeek(0);
  }

  ngOnInit(): void {
    console.log(this.dates);
  }

  // selectWeek(date: Date): any[] {
  //   let selectWeek = [];
  //   for (let i = 0; i < 7; i++) {
  //     const weekday = new Date(date);
  //     const selectedWeekdayIndex = date.getDay();
  //     const selectedDay = date.getDate();
  //     weekday.setDate(selectedDay - selectedWeekdayIndex + i);
  //     selectWeek = [...selectWeek, weekday];
  //   }
  //   return selectWeek;
  // }

  selectDate(date): void {
    this.selectedDate = date.date;
  }

  nextWeek(): void {
    this.changeDisplayedWeek(7);
  }

  prevWeek(): void {
    this.changeDisplayedWeek(-7);
  }

  private changeDisplayedWeek(daysToAdd): void {
    const selectedDate = moment(this.selectedDate).add(daysToAdd, 'days');
    this.selectedDate = selectedDate.format();
    this.weekOfYear = selectedDate.format('WW');
    this.dates = this.expandWeek(selectedDate);
  }

  private expandWeek(startDate): any[] {
    const dates = [];
    const dayOfWeek = moment(startDate).startOf('isoWeek');
    for (let i = 0; i < 7; i++) {
      dates.push({
        weekDay: dayOfWeek.format('dd'),
        shortDate: dayOfWeek.format('DD').replace(/(^|\s)0/g, '$1'),
        date: dayOfWeek.format(),
      });
      dayOfWeek.add(1, 'd');
    }

    return dates;
  }
}
