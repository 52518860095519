import { Balance, FinanceFullData, FinanceSchedule, PayHistory, UserFinanceInformation } from './finance.models';
import { IPageableList, PageableList } from '@Mesh/core/models/pageable';
import { FinanceSum } from '@Mesh/core/models/finance-sum';

export interface FinanceState {
  payHistory: IPageableList<PayHistory>;
  balance: IPageableList<Balance>;
  totalPages: number;
  errors: any[] | null;
  financeSum: FinanceSum;
  financeFullData: FinanceFullData[];
  userFinanceInformation: UserFinanceInformation;
}

export const initialState: FinanceState = {
  payHistory: PageableList.empty(),
  balance: PageableList.empty(),
  totalPages: 0,
  errors: null,
  financeSum: null,
  financeFullData: null,
  userFinanceInformation: null,
};
