import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPageableList } from '@Mesh/core/models/pageable';
import { GetClientTasksCriteria } from '@Mesh/core/models/request/get-client-tasks-criteria';
import { stringifyObject } from '@Mesh/core/services/util';
import { API_TASK_URL } from '@Env/environment';
import { Task, TaskMessage, TaskProgress } from '@Mesh/core/models/task';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  readonly _messageType: TaskMessage = {
    isNew: 'Начните выполнять задание',
    inModeration: 'На модерации',
    inProcess: 'В процессе выполнения',
    isSuccess: 'Задание выполнено',
  };

  private baseUrl = API_TASK_URL;

  constructor(private readonly http: HttpClient) {}

  get messageType() {
    return this._messageType;
  }

  getClientTasks(data: Partial<GetClientTasksCriteria>): Observable<IPageableList<Task>> {
    const stringData = stringifyObject(data);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<IPageableList<Task>>(`${this.baseUrl}/client-task`, { params });
  }

  geProgressTask(data: { taskOutletClientId: number }): Observable<TaskProgress> {
    const stringData = stringifyObject(data);
    const params = new HttpParams({ fromObject: stringData });
    return this.http.get<TaskProgress>(`${this.baseUrl}/task/progress/outlet`, { params });
  }
}
