import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { OrderDetails, Order } from '@Mesh/core/models/order';
import { RecommendOrderBonus } from './order.model';
import { DelayedOrder } from '../../core/models/delayed-order';

export interface OrderState extends Loadable {
  orders: Order[] | [];
  historyOrders: Order[] | []; // need for dashboard page
  totalCount: number;
  orderDetails: OrderDetails[] | [];
  selectedDocNumber: string | null;
  errors: any[] | null;
  totalHistoryCount: number;
  totalPages: number;
  activeOrdersCurrentPage: number;
  hisrotyOrdersCurrentPage: number;
  ordersHistoryLoading: boolean;
  bonusRecommendedOrders: RecommendOrderBonus[] | null;
  bonusRecommendedOrdersByCatalog: RecommendOrderBonus[] | null;
  delayedOrders: DelayedOrder[];
  totalCountDelayOrder: number;
  editedDelayedOrder: DelayedOrder;
  ordersProducts: {};
  ordersUsers: {};
  errorsDelayedOrder: any;
  completedUpdateDelayedOrder: boolean;
}

export const initialState: OrderState = {
  ...createDefaultLoadable(),
  orders: [],
  historyOrders: [],
  totalCount: 0,
  orderDetails: [],
  selectedDocNumber: null,
  errors: null,
  totalHistoryCount: 0,
  totalPages: 0,
  activeOrdersCurrentPage: 0,
  hisrotyOrdersCurrentPage: 0,
  ordersHistoryLoading: false,
  bonusRecommendedOrders: [],
  bonusRecommendedOrdersByCatalog: [],
  delayedOrders: [],
  totalCountDelayOrder: 0,
  editedDelayedOrder: null,
  ordersProducts: {},
  ordersUsers: {},
  errorsDelayedOrder: null,
  completedUpdateDelayedOrder: false,
};
