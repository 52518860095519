import { Component, OnDestroy, OnInit } from '@angular/core';
import { Metrika } from 'ng-yandex-metrika';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { GetUser } from '@Mesh/store/login/login.actions';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <ngx-ui-loader></ngx-ui-loader>`,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'app works!';
  private _subscriptions: Subscription;

  constructor(
    // private metrika: Metrika,
    private router: Router,
    private store: Store<AppState>
  ) {
    console.log(localStorage);
    // @ts-ignore
    let prevPath = window.location.href;

    /*this.metrika.hit(prevPath, {
      referer: prevPath,
    });*/

    this._subscriptions = this.router.events
      // .filter(event => (event instanceof NavigationEnd))
      .subscribe((event) => {
        // @ts-ignore
        if (event instanceof NavigationEnd) {
          const newPath = window.location.href;
          /*this.metrika.hit(newPath, {
                    referer: prevPath,
                });*/
          console.log(newPath);
          prevPath = newPath;
        }
        if (event instanceof RouteConfigLoadStart) {
          // lazy loading module start
        }
        if (event instanceof RouteConfigLoadEnd) {
          // lazy loading module end
        }
      });
  }

  ngOnInit() {
    this.store.dispatch(new GetUser());
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
