import { SalePlans } from '@Mesh/core/models/product';
import { getDescendantProp } from '@Mesh/shared/helpers/object.helpers';
import { TableColumn, TableColumnType } from '@Mesh/core/models/table';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable({ providedIn: 'root' })
export class CatalogHelper {
  catalogColumns: TableColumn[] = [
    {
      type: TableColumnType.textCardImage,
      headerClass: 'align-center ',
      key: 'materialName',
      class: 'd-flex align-items-center',
      img: 'icon',
      label: 'Название',
      sortable: true,
      minWidth: 350,
    },
    // {
    //   type: TableColumnType.text,
    //   headerClass: 'align-center text-center',
    //   key: 'stock.0.mrc',
    //   class: 'd-flex align-items-center text-center',
    //   label: 'МРЦ',
    //   subName: '₽',
    //   maxWidth: 220,
    // },
    {
      type: TableColumnType.distributorName,
      headerClass: 'align-center text-center',
      key: 'distributorName',
      class: 'd-flex align-items-center text-center justify-content-center',
      label: 'Поставщик',
      maxWidth: 220,
    },
    {
      type: TableColumnType.stock,
      headerClass: 'align-center text-center',
      key: 'stock',
      class: 'd-flex align-items-center justify-content-center text-center',
      label: 'Ед. измерения',
      minWidth: 220,
      maxWidth: 220,
    },
    // {
    //   type: TableColumnType.text,
    //   headerClass: 'align-center text-center',
    //   key: 'stock.0.baseNdsPrice',
    //   class: 'd-flex align-items-center text-center',
    //   label: 'Цена за ШТ',
    //   subName: '₽',
    //   minWidth: 220,
    //   maxWidth: 220,
    // },
    {
      type: TableColumnType.priceForOne,
      headerClass: 'align-center text-center',
      class: 'd-flex align-items-center justify-content-center text-center',
      label: 'Цена за ШТ',
      minWidth: 220,
      maxWidth: 220,
    },
    {
      type: TableColumnType.price,
      headerClass: 'align-center text-center',
      key: 'price',
      class: 'd-flex align-items-center justify-content-center text-center',
      label: 'Цена',
      subName: 'БЛК',
      minWidth: 220,
      maxWidth: 220,
    },
    // {
    //     type: TableColumnType.text,
    //     headerClass: 'align-center text-center',
    //     key: 'brandName',
    //     class: 'd-flex align-items-center text-center',
    //     label: 'Бренд',
    //     minWidth: 220,
    //     maxWidth: 220
    // },
    // {
    //     type: TableColumnType.text,
    //     headerClass: 'align-center text-center',
    //     key: 'manufacturerName',
    //     class: 'd-flex align-items-center text-center',
    //     label: 'Производитель',
    //     minWidth: 220,
    //     maxWidth: 220,
    //     removeBreakpoint: 1920
    // },
    {
      type: TableColumnType.quantityButtons,
      headerClass: 'align-center text-center',
      label: 'Количество позиций',
      class: 'd-flex align-items-center text-center ',
      minWidth: 250,
      maxWidth: 250,
      removeBreakpoint: 1600,
    },
  ];

  planColumns: TableColumn[] = [
    {
      type: TableColumnType.textCardImage,
      headerClass: 'align-center ',
      key: 'materialName',
      class: 'd-flex align-items-center',
      img: 'icon',
      label: 'Название',
      sortable: true,
      //   minWidth: 300,
    },
    {
      type: TableColumnType.progress,
      headerClass: 'align-center ',
      key: 'progress',
      class: 'd-flex align-items-center',
      img: 'icon',
      label: 'Прогресс',
      sortable: false,
      maxWidth: 81,
      minWidth: 81,
    },
    {
      type: TableColumnType.stock,
      headerClass: 'align-center text-center',
      key: 'stock',
      class: 'd-flex align-items-center justify-content-center text-center',
      label: 'Выбор ЕИ',
      subName: 'БЛК',
      //   minWidth: 220,
      //   maxWidth: 220,
    },
    {
      type: TableColumnType.price,
      headerClass: 'align-center text-center',
      key: 'price',
      class: 'd-flex align-items-center justify-content-center text-center',
      label: 'Цена',
      subName: 'БЛК',
      //   minWidth: 220,
      //   maxWidth: 220,
    },
    {
      type: TableColumnType.text,
      headerClass: 'align-center text-center',
      key: 'stock.0.mrc',
      class: 'd-flex align-items-center text-center',
      label: 'МРЦ',
      subName: '₽',
      //   maxWidth: 220,
    },
    // {
    //     type: TableColumnType.text,
    //     headerClass: 'align-center text-center',
    //     key: 'brandName',
    //     class: 'd-flex align-items-center text-center',
    //     label: 'Бренд',
    //     subName: '₽',
    //     minWidth: 220,
    //     maxWidth: 220,
    //     removeBreakpoint: 1600
    // },
    // {
    //     type: TableColumnType.text,
    //     headerClass: 'align-center text-center',
    //     key: 'manufacturerName',
    //     class: 'd-flex align-items-center text-center',
    //     label: 'Производитель',
    //     subName: '₽',
    //     minWidth: 220,
    //     maxWidth: 220,
    //     removeBreakpoint: 1400
    // },
    {
      type: TableColumnType.quantityButtons,
      headerClass: 'align-center text-center',
      label: 'Количество',
      class: 'd-flex align-items-center text-center ',
      //   minWidth: 250,
      //   maxWidth: 250,
    },
  ];
  locationDropdownHeaderToggle$ = new Subject<boolean>();

  recalcTableSize() {
    for (let i = 0; i < this.catalogColumns.length; i++) {
      if (i === 0 || i === this.catalogColumns.length - 1) {
        this.catalogColumns[i].maxWidth = 255;
        this.catalogColumns[i].minWidth = 255;
      } else {
        this.catalogColumns[i].maxWidth = 'auto';
        this.catalogColumns[i].minWidth = 'auto';
      }
    }
  }

  getShortPlanName(salePlan: SalePlans) {
    const plan = {
      3500000039: 'RedBull',
      2222222212: 'ТОП 24',
      2222222213: 'Drive List',
      2222222214: 'ТОП SKU',
      3500000028: 'Табак Вал',
      1: 'PMI Вал',
      2: 'JTI Вал',
      3: 'ITG Вал',
      7: 'IQOS Вал',
      3500000012: 'НТА',
    }[salePlan.planId];

    return plan ?? salePlan.planName;
  }

  filteredPlans(salePlans: SalePlans[]) {
    return salePlans;
    // return salePlans.filter(
    //     ({planId}) => [3500000039, 3500000028, 1, 2, 3, 7, 2222222214, 2222222212, 2222222213, 3500000012].indexOf(planId) !== -1
    // );
  }

  filterPlansByPlanId(salePlans: SalePlans[], planId: number): SalePlans[] {
    return salePlans.filter((el) => el.planId === planId);
  }

  getLabelBg(planId: number) {
    switch (planId) {
      case 3500000039:
        return 'linear-gradient(135deg, #FF1680 0%, #FF0909 100%)';
      case 3500000028:
      case 1:
      case 2:
      case 3:
      case 7:
        return 'linear-gradient(46.19deg, #F09819 5.66%, #EDDE5D 94.35%)';
      case 2222222214:
        return 'linear-gradient(78.43deg, #B982FF 3.79%, #FA51FD 93.8%)';
      case 2222222213:
        return 'linear-gradient(78.43deg, #B982FF 3.79%, #FA51FD 93.8%)';
      case 3500000012:
        return 'linear-gradient(78.43deg, #B982FF 3.79%, #FA51FD 93.8%)';
      case 2222222212:
        return 'linear-gradient(38.22deg, #6558F7 38.08%, #D7D0FF 197.55%)';
      default:
        return 'linear-gradient(135deg, #FF1680 0%, #FF0909 100%)';
    }
  }

  getBackground(planId: number): string {
    switch (planId) {
      case 3500000039:
        return 'bg-red';
      case 3500000028:
      case 1:
      case 2:
      case 3:
      case 7:
        return 'bg-yellow';
      case 2222222214:
        return 'bg-purple';
      case 2222222213:
        return 'bg-purple';
      case 3500000012:
        return 'bg-purple';
      case 2222222212:
        return 'bg-blue';
      default:
        return 'bg-pink';
    }
  }

  rowByKey(row, key) {
    return getDescendantProp(row, key);
  }

  onLocationDropdown(toggle: boolean) {
    this.locationDropdownHeaderToggle$.next(toggle);
  }
}
