import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketComponent } from './basket.component';
import { MinimalCardRulesModule } from '../components/minimal-card-rules/minimal-card-rules.module';
import { BasketItemComponent } from './basket-item/basket-item.component';
import { ProgressRecommendedOrderModule } from './progress-recommended-order/progress-recommended-order.module';
import { RecommendedOrdersInfoComponent } from './recommended-orders-info/recommended-orders-info.component';
import { SuccessScreenModule } from './success-screen/success-screen.module';
import { RecommendedOrdersComponent } from './recommended-orders/recommended-orders.component';
import { AutoOrderInfoModule } from '../components/auto-order-info/auto-order-info.module';
import { BasketFillMinimalAlertComponent } from './basket-fill-minimal-alert/basket-fill-minimal-alert.component';
import { BasketHeaderModule } from './basket-header/basket-header.module';
import { BasketDeliveryModule } from './basket-delivery/basket-delivery.module';
import { RecommendedCartListComponent } from './recommended-cart-list/recommended-cart-list.component';
import { FilterListPipe } from './recommended-cart-list/filter-list.pipe';

@NgModule({
  declarations: [
    BasketComponent,
    BasketItemComponent,
    RecommendedOrdersInfoComponent,
    RecommendedOrdersComponent,
    BasketFillMinimalAlertComponent,
    RecommendedCartListComponent,
    FilterListPipe,
  ],
  exports: [BasketComponent],
  imports: [
    CommonModule,
    BasketHeaderModule,
    BasketDeliveryModule,
    MinimalCardRulesModule,
    ProgressRecommendedOrderModule,
    SuccessScreenModule,
    AutoOrderInfoModule,
  ],
  entryComponents: [BasketFillMinimalAlertComponent],
})
export class BasketModule {}
