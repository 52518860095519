import { LogoutRequest } from '@Mesh/store/login/login.actions';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IUserInfo } from '@Mesh/core/models';
import { AuthenticationService } from '@Mesh/core/services';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private token;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authenticationService: AuthenticationService, private store: Store<AppState>) {
    this.store.select(loginSelectors.selectToken).subscribe((token) => {
      this.token = token;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    request = this.addContentTypeJson(request);
    request = this.removeNoContentTypeHeader(request);

    if (this.token) {
      request = this.addTokenHeader(request, this.token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          return this.handleUnauthorizedError(request, next);
        }
        return throwError(error);
      })
    );
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authenticationService.updateTokens().pipe(
        switchMap((userInfo: IUserInfo) => {
          // this.store.dispatch(refreshTokenSuccess({userInfo: userInfo}));
          this.isRefreshing = false;

          this.accessTokenSubject.next(userInfo.accessToken);

          return next.handle(this.addTokenHeader(request, userInfo.accessToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;

          this.store.dispatch(new LogoutRequest());
          return throwError(err);
        })
      );
    }

    return this.accessTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private addContentTypeJson(request: HttpRequest<any>) {
    if (!request.headers.get('Content-Type') && !request.headers.has('no-content-type')) {
      return request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    return request;
  }

  private removeNoContentTypeHeader(request: HttpRequest<any>) {
    if (request.headers.has('no-content-type')) {
      return request.clone({ headers: request.headers.delete('no-content-type') });
    }
    return request;
  }
}
