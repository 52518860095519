import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ruLocale } from 'ngx-bootstrap/chronos/i18n/ru';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { Task } from '@Mesh/core/models/task';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { TaskLoadRequest, SetVisitTaskActive } from '@Mesh/store/task/task.actions';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
/* Visits
Education
Personal */
@Component({
  selector: 'app-tasks-sales-agent-filter-calendar',
  templateUrl: './tasks-sales-agent-filter-calendar.component.html',
  styleUrls: ['./tasks-sales-agent-filter-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSalesAgentFilterCalendarComponent implements OnInit, OnDestroy {
  @Output() selectedDate: EventEmitter<any> = new EventEmitter();
  private unsubscribe$ = new Subject();
  tasks$: Observable<Array<Task>> = this.store.select(taskSelectors.getActiveTasksTasks);
  currentEvent: Event;
  calendarPlugins = [interactionPlugin, dayGridPlugin];
  locales = [ruLocale];
  selected: { startDate: moment.Moment; endDate: moment.Moment };
  header = {
    center: 'title',
    left: 'prev',
    right: 'next',
  };
  events = [];
  mockEvents = [
    {
      title: '4',
      start: new Date(),
      classNames: ['event-task', 'event-visits'],
    },
    {
      title: '1',
      start: new Date(),
      classNames: ['event-task', 'event-education'],
    },
    {
      title: '4',
      start: '2022-07-13T15:00:00',
      classNames: ['event-task', 'event-visits'],
    },
    {
      title: '5',
      start: '2022-07-05T13:00:00',
      classNames: ['event-task', 'event-personal'],
    },
    {
      title: '1',
      start: '2022-07-13T13:00:00',
      classNames: ['event-task', 'event-education'],
    },
    {
      title: '4',
      start: '2022-07-20T13:00:00',
      classNames: ['event-task', 'event-personal'],
    },
    {
      title: '1',
      start: '2022-07-20T13:00:00',
      classNames: ['event-task', 'event-education'],
    },
    {
      title: '7',
      start: '2022-07-29T13:00:00',
      classNames: ['event-task', 'event-visits'],
    },
    {
      title: '3',
      start: '2022-07-29T13:00:00',
      classNames: ['event-task', 'event-education'],
    },
  ];
  eventsToday = {};
  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(new TaskLoadRequest({ pageNumber: 0, pageSize: 9999, completed: false, onlyActual: true, notOverdue: true }));
    this.tasks$.pipe(takeUntil(this.unsubscribe$)).subscribe((tasks: Array<Task>) => {
      let mapTasks: any = {};
      for (const task of tasks) {
        if (!mapTasks[task.dateEnd]) {
          mapTasks[task.dateEnd] = [task];
        } else {
          mapTasks[task.dateEnd].push(task);
        }
      }
      for (const mapTask of Object.entries(mapTasks)) {
        this.events.push({
          title: (mapTask[1] as Array<any>).length.toString(),
          start: mapTask[0] + 'T00:00:00',
          classNames: ['event-task', 'event-visits'],
          extendedProps: {
            tasks: mapTask[1],
          },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onEventClick(event): void {
    this.store.dispatch(new SetVisitTaskActive(event.event.extendedProps.tasks[0]));
    this.router.navigate(['/active/task/visit']);
    // this.selectedDate.emit(event);
  }

  onDateSelect(event): void {
    this.selectedDate.emit(event);
  }
}
