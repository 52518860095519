import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface IProductParam {
  clientSapId: number;
  addressSapId: number;
  pageNumber: number;
  pageSize: number;
  sort: string;
}

export interface IProductParamCategory extends IProductParam {
  materialName?: string;
  manufacturerNameId?: string;
  categoryNameId?: string;
  brandNameId?: string;
  brandFamilyNameId?: string;
  productTypeNameId?: string;
  materialGroupNameId?: string;
  planId?: number;
}

export interface IProductParamPlan extends IProductParam {
  planId: number;
}

class ProductParam implements IProductParam {
  addressSapId: number = null;
  clientSapId: number = null;
  pageNumber: number = 0;
  pageSize: number = 12;
  sort = 'rank|ASC';

  static empty() {
    return new ProductParam();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTableService {
  readonly queryParam = new BehaviorSubject<IProductParam>(ProductParam.empty());
  private readonly queryParam$ = this.queryParam.asObservable();

  readonly queryParamChanged$ = this.queryParam$.pipe(
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    debounceTime(300)
  );

  setQuery(query: IProductParam) {
    this.queryParam.next(query);
  }
}
