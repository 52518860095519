import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TaskStepTypes } from '../task-sales-agent.model';

@Component({
  selector: 'app-task-sales-agent-view',
  templateUrl: './task-sales-agent-view.component.html',
  styleUrls: ['./task-sales-agent-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSalesAgentViewComponent implements OnInit {
  @Output() closed: EventEmitter<any> = new EventEmitter();
  taskStepTypes = TaskStepTypes;

  constructor() {}

  ngOnInit(): void {}

  closeModal(): void {
    this.closed.emit();
  }
}
