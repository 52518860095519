import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskSalesAgentViewComponent } from './task-sales-agent-view.component';
import { TaskSalesAgentViewStepComponent } from './task-sales-agent-view-step/task-sales-agent-view-step.component';
import { TasksSalesAgentTagsModule } from '../tasks-sales-agent-tags/tasks-sales-agent-tags.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';

@NgModule({
  declarations: [TaskSalesAgentViewComponent, TaskSalesAgentViewStepComponent],
  exports: [TaskSalesAgentViewComponent],
  entryComponents: [TaskSalesAgentViewComponent],
  imports: [CommonModule, TasksSalesAgentTagsModule, ProgressbarModule.forRoot(), AngularYandexMapsModule],
})
export class TaskSalesAgentViewModule {}
