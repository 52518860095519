import { uniqueId } from 'lodash';

export enum TaskStepTypes {
  SEND_PHOTO = 'SEND_PHOTO', // 5. Сделать фото
  PHOTO_MONITORING = 'PHOTO_MONITORING', // 4. Фото-мониторинг стеллажей
  GIVE_ARBITRARY_ANSWER = 'GIVE_ARBITRARY_ANSWER', // 2. Свободная форма
  SELECT_OPTION = 'SELECT_OPTION', // 1. Анкетирование
  CALCULATE_REST_OF_PRODUCT = 'CALCULATE_REST_OF_PRODUCT', // 3. Остатки
  AUTO_RECOMMENDED_ORDER = 'AUTO_RECOMMENDED_ORDER',
}

export class TaskStep {
  id: string;
  title: string;
  description: string;
  stepType: string;
  comment: string;
  manualModeration: boolean;
  content: any;

  constructor() {
    this.id = uniqueId();
  }
}
