import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { API_USER_URL } from '@Env/environment';
import { IUserInfo, IUserInfoResponse, User } from '../models/user';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { Authorized, Login, RegConfirm, RegisterPhone, Entity } from '@Mesh/core/models/login';
import {
  Participant,
  ParticipantRequestPost,
  ParticipantRequestPostConfirm,
  ParticipantResponsePostConfirm,
  ParticipantRequestGet,
} from '@Mesh/core/models/participant';
import { PageableV2List } from '@Mesh/core/models/pageable-v2';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { LoginSuccess } from '@Mesh/store/login/login.actions';
import { PostApplication } from '@Mesh/core/models/request/post-application';
import { PostRegVerify, PostRequestSignUp } from '@Mesh/core/models/request/post-auth';
import { SetPasswordRequestBody, VerifyUpdatePasswordRequestBody } from '@Mesh/core/models/request/password-models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _currentUserToken: BehaviorSubject<string>;
  private _refreshToken: string;
  private _currentUser: User;
  private userRegisterInfo = {
    code: '',
    identifier: null,
    phoneNumber: '',
  };

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private feathers: FeathersService,
    private store: Store<AppState>
  ) {
    // const user = localStorage.getItem('user');
    // console.log('user:', user);
    // this.currentUser = new User().deserialize(user);
    // this._currentUserToken = new BehaviorSubject<string>(this.token);
  }

  get currentUser(): User {
    return this._currentUser;
  }

  get currentUserToken(): BehaviorSubject<string> {
    return this._currentUserToken;
  }

  get refreshToken(): string {
    return this._refreshToken;
  }

  set refreshToken(value: string) {
    this._refreshToken = value;
  }

  get userRegisterInfoValue() {
    return this.userRegisterInfo;
  }

  set setUserRegisterInfo(value) {
    this.userRegisterInfo.identifier = value.identifier;
    this.userRegisterInfo.phoneNumber = value.phoneNumber;
    this.userRegisterInfo.code = value.code;
  }

  set currentUser(value: User) {
    this._currentUser = value;
    // if (value) {
    //   //this.localStorage.setItem('user', this._currentUser);

    //   this.feathers.authenticate(this.token, value);
    //   this.store.dispatch(new LoginSuccess({ token: this.token, user: value }));
    // } else {
    //   this.localStorage.removeItem('user');
    // }
  }

  get token(): string {
    return this.localStorage.getItem('token');
  }

  login(user: Login): Observable<IUserInfo> {
    const params = {
      phone: '7' + user.phone,
      password: user.password,
    };
    return this.http.post<IUserInfo>(`${API_USER_URL}/token`, { ...params }).pipe(
      map((response: any) => {
        return {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          accessTokenExpires: response.accessTokenExpires,
          refreshTokenExpires: response.refreshTokenExpires,
          user: response.user,
        } as IUserInfo;
      })
    );
  }

  updateTokens(): Observable<IUserInfo> {
    return this.http
      .post<IUserInfo>(`${API_USER_URL}/token/refresh-token`, {
        refreshToken: this.refreshToken,
      })
      .pipe(
        map((response: any) => {
          const userInfo = {
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            accessTokenExpires: response.accessTokenExpires,
            refreshTokenExpires: response.refreshTokenExpires,
            user: response.user,
          } as IUserInfo;
          this.setUserData(userInfo);

          this.store.dispatch(
            new LoginSuccess({
              token: response.access_token,
              user: response.user,
            })
          );
          return userInfo;
        })
      );
  }

  getLegalEntities(): Observable<Array<Entity>> {
    return this.http.get<any>(`${API_USER_URL}/user/client`, {});
  }

  checkExistPhoneNumber(phoneNumber: string): Observable<Authorized> {
    return this.http.post<Authorized>(`${API_USER_URL}/user/check/pre-authorization/${phoneNumber}`, {});
  }

  registerPhoneNumber(phoneNumber: string): Observable<RegisterPhone> {
    return this.http.get<RegisterPhone>(`${API_USER_URL}/registration`, {
      params: { phoneNumber },
    });
  }

  registerPhoneNumberConfirm(identifier: number): Observable<RegConfirm> {
    const idn: string = identifier.toString();
    return this.http.post<RegConfirm>(`${API_USER_URL}/registration/confirm`, {
      identifier: idn,
    });
  }

  acceptCode(obj: PostRegVerify) {
    return this.http.post<any>(`${API_USER_URL}/registration/verify`, {
      ...obj,
    });
  }

  signup(obj: PostRequestSignUp): Observable<IUserInfo> {
    const objj = { ...obj, identifier: obj.identifier.toString() };
    return this.http
      .post<IUserInfoResponse>(`${API_USER_URL}/registration/signup`, {
        ...objj,
      })
      .pipe(
        map((response) => {
          return {
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            accessTokenExpires: response.accessTokenExpires,
            refreshTokenExpires: response.refreshTokenExpires,
            user: response.user,
          } as IUserInfo;
        })
      );
  }

  application(obj: PostApplication): Observable<void> {
    return this.http.post<void>(`${API_USER_URL}/client/application`, {
      ...obj,
    });
  }

  logout() {
    if (window.location.href.indexOf('auth/login') === -1) {
      window.location.replace('/auth/login');
    }
    // TODO: перенести это в meta reducer
    // remove user from local storage to log user out
    // if (this.localStorage.getItem('token')) {
    //     this.localStorage.removeItem('token');
    // }
    // if (this.localStorage.getItem('refresh-token')) {
    //     this.localStorage.removeItem('refresh-token');
    // }
    // if (this.localStorage.getItem('isAuthenticated')) {
    //     this.localStorage.removeItem('isAuthenticated');
    // }
    // if (this.localStorage.getItem('user')) {
    //     this.localStorage.removeItem('user');
    //     this.currentUser = null;
    //     if (window.location.href.indexOf('auth/login') !== -1) {
    //         window.location.reload();
    //     } else {
    //         window.location.replace('/auth/login');
    //     }
    // } else {
    //     if (window.location.href.indexOf('auth/login') === -1) {
    //         window.location.replace('/auth/login');
    //     }
    // }
  }

  updatePasswordRequest(phoneNumber: string): Observable<{ identifier: number }> {
    return this.http.post<{ identifier: number }>(`${API_USER_URL}/password`, {
      phoneNumber,
    });
  }

  updatePasswordVerify(body: VerifyUpdatePasswordRequestBody): Observable<void> {
    return this.http.post<void>(`${API_USER_URL}/password/verify`, body);
  }

  setNewPassword(body: SetPasswordRequestBody): Observable<IUserInfo> {
    return this.http.post<IUserInfoResponse>(`${API_USER_URL}/password/set`, body).pipe(
      map((response) => {
        return {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          accessTokenExpires: response.accessTokenExpires,
          refreshTokenExpires: response.refreshTokenExpires,
          user: response.user,
        } as IUserInfo;
      })
    );
  }

  private setUserData(userInfo: IUserInfo): void {
    // this.localStorage.setItem('user', userInfo.user);
    // this.localStorage.setItem('token', userInfo.accessToken);
    // this.localStorage.setItem('refresh-token', userInfo.refreshToken);
    // this.localStorage.setItem('isAuthenticated', true);

    // this.currentUserToken.next(userInfo.accessToken);
    this.refreshToken = userInfo.refreshToken;
    this.currentUser = new User().deserialize(userInfo.user);
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<User>(API_USER_URL + '/user/' + userId);
  }

  setClient(clientSapId: number): Observable<IUserInfo> {
    return this.http.post<IUserInfo>(API_USER_URL + '/user/client/change', { clientSapId }).pipe(
      map((response: any) => {
        const userInfo = {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          accessTokenExpires: response.accessTokenExpires,
          refreshTokenExpires: response.refreshTokenExpires,
          user: response.user,
        } as IUserInfo;
        this.setUserData(userInfo);
        return userInfo;
      })
    );
  }

  getParticipant(body: ParticipantRequestGet): Observable<PageableV2List<Participant>> {
    return this.http.get<PageableV2List<Participant>>(API_USER_URL + '/participant');
  }

  createParticipant(body: ParticipantRequestPost): Observable<Participant> {
    // const mock: Participant = {
    //   id: 1234,
    //   organizationName: "organization OOO",
    //   inn: "123456789",
    //   kpp: "987654321",
    //   fullName: "Alex Reev",
    //   position: "owner",
    //   phone: "+79998887766",
    //   email: "example@m.com",
    //   addresses: 5,
    //   city: "Moscow"
    // }
    // return of(mock);
    return this.http.post<Participant>(API_USER_URL + '/participant', body);
  }

  sendSmsCodeParticipant(body: ParticipantRequestPostConfirm): Observable<ParticipantResponsePostConfirm> {
    // const mock: ParticipantResponsePostConfirm = {
    //   identifier: '123456789'
    // }
    // return of(mock);
    return this.http.post<ParticipantResponsePostConfirm>(API_USER_URL + '/participant/confirm', body);
  }
}
