import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  CartItem,
  DeleteCartItem,
  DeleteCartItemCriteria,
  LoadCartCriteria,
  MinimalCartRuleGroupedStatus,
  MinimalCartRules,
  MinimalCartRulesCriteria,
  UpdateCartItem,
  OrderCompleted,
} from '@Mesh/store/cart/cart.models';
import { API_PLAN_URL } from '@Env/environment';
import { objectToParams } from '../../../shared/helpers/object.helpers';

@Injectable({ providedIn: 'root' })
export class CartService {
  url = API_PLAN_URL;

  constructor(private httpClient: HttpClient) {}

  loadCart(addressSapId: number, criteria: LoadCartCriteria): Observable<CartItem[]> {
    const params = objectToParams(criteria);
    return this.httpClient.get<CartItem[]>(`${this.url}/v1/cart/${addressSapId}`, { params });
  }

  // submitCart(addressSapId: number, remark: string, userId: number, recommendedOrderId?: number): Observable<{ orderNumber: string }> {
  //   return this.httpClient.post<{ orderNumber: string }>(`${this.url}/v1/cart/${addressSapId}/submit`, {
  //     remark,
  //     userId,
  //     recommendedOrderId,
  //   });
  // }

  submitCart(addressSapId: number, remark: string, userId: number, recommendedOrderId?: number): Observable<OrderCompleted[]> {
    return this.httpClient.post<OrderCompleted[]>(`${this.url}/v2/cart/${addressSapId}/submit`, {
      remark,
      userId,
      recommendedOrderId,
    });

    // const mock = [
    //   {
    //     orderNumber: "123",
    //     orderSum: 777,
    //     type: "NORMAL",
    //     delayedTo: "2022-07-06T07:41:46.217Z",
    //     carts: [
    //       {
    //         id: 0,
    //         materialId: 10048071,
    //         quantity: 3,
    //         mrc: 135,
    //         unit: "КОР",
    //         price: 53875,
    //         ndsPrice: 64650,
    //         discountPrice: 26937500,
    //         discountNdsPrice: 32325000,
    //         userId: 0,
    //         type: "NORMAL",
    //         salesOrgSapId: "D03004",
    //         distributorCode: "7700",
    //         distributorName: "ТК Мегаполис"
    //       }
    //     ],
    //     distributorName: "ТК Мегаполис"
    //   }, {
    //     orderNumber: "123",
    //     orderSum: 777,
    //     type: "NORMAL",
    //     delayedTo: "2022-07-06T07:41:46.217Z",
    //     carts: [
    //       {
    //         id: 1234,
    //         materialId: 10047809,
    //         quantity: 2,
    //         mrc: 135,
    //         unit: "БЛК",
    //         price: 1077.5,
    //         ndsPrice: 1293,
    //         discountPrice: 10775,
    //         discountNdsPrice: 12930,
    //         userId: 0,
    //         type: "NORMAL",
    //         salesOrgSapId: "D03004",
    //         distributorCode: "77M0",
    //         distributorName: "Мегаком"
    //       }, {
    //         id: 1234,
    //         materialId: 10049775,
    //         quantity: 2,
    //         mrc: 135,
    //         unit: "КОР",
    //         price: 53804.17,
    //         ndsPrice: 64565,
    //         discountPrice: 26902085,
    //         discountNdsPrice: 32282500,
    //         userId: 0,
    //         type: "NORMAL",
    //         salesOrgSapId: "D03004",
    //         distributorCode: "77M0",
    //         distributorName: "Мегаком"
    //       }
    //     ],
    //     distributorName: "Мегаком"
    //   }
    // ]
    // return of(mock)
  }

  updateCart(addressSapId: number, body: UpdateCartItem): Observable<CartItem> {
    return this.httpClient.put<CartItem>(`${this.url}/v1/cart/${addressSapId}`, body);
  }

  deleteProduct(addressSapId: number, body: DeleteCartItemCriteria): Observable<void> {
    return this.httpClient.request<void>('delete', `${this.url}/v1/cart/${addressSapId}`, { body });
  }

  updateRecommendedOrderCart(addressSapId: number, recommendedOrderId: number, payload: { userId: number }): Observable<CartItem[]> {
    return this.httpClient.put<CartItem[]>(`${this.url}/v1/cart/${addressSapId}/${recommendedOrderId}`, payload);
  }

  getClosestDeliveryDate(addressSapId: number): Observable<{ deliveryDate: string }> {
    return this.httpClient.get<{ deliveryDate: string }>(`${this.url}/v1/delivery-date/${addressSapId}/nearest`);

    // const mock = { deliveryDate: "2022-22-06T07:41:46.217Z" }
    // return of(mock)
  }

  getMinimalCartRulesByCategory(addressSapId: number, criteria: MinimalCartRulesCriteria): Observable<MinimalCartRules[]> {
    const params = objectToParams(criteria);
    return this.httpClient.get<MinimalCartRules[]>(`${this.url}/v1/minimal-cart-rule/by-category/${addressSapId}`, { params });
  }

  getMinimalCartRuleGroupedStatus(addressSapId: number, criteria: MinimalCartRulesCriteria): Observable<MinimalCartRuleGroupedStatus> {
    const params = objectToParams(criteria);
    return this.httpClient.get<MinimalCartRuleGroupedStatus>(`${this.url}/v1/minimal-cart-rule/status/${addressSapId}`, { params });
  }

  fillUpCartsMinimalLimit(addressSapId: number, criteria: { userId: number }): Observable<any> {
    const params = objectToParams(criteria);
    return this.httpClient.post<any>(`${this.url}/v1/cart/${addressSapId}/fill-up-to-minimal`, { id: null }, { params });
  }
}
