import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DelayedOrder, DelayedOrderCart, DelayedOrderHistory, DelayedOrderUpdatePayload } from '../../../../core/models/delayed-order';
import { AppState } from '../../../../store/app.state';
import { CartActions, CartSelectors } from '../../../../store/cart';
import { MinimalCartRules } from '../../../../store/cart/cart.models';
import { ResetUpdateDelayedOrder, UpdateDelayedOrder } from '../../../../store/order/order.actions';
import { orderSelectors } from '../../../../store/order/order.selectors';
import { outletSelectors } from '../../../../store/outlet/outlet.selectors';
import { DelayedOrderUpdateNotifyComponent } from './delayed-order-update-notify/delayed-order-update-notify.component';

@Component({
  selector: 'app-delayed-order-edit',
  templateUrl: './delayed-order-edit.component.html',
  styleUrls: ['./delayed-order-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderEditComponent implements OnInit, OnDestroy {
  @Input() orderCarts: DelayedOrderCart[];
  @Input() delayedOrder: DelayedOrder;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  readonly activeOutlet$ = this.store.select(outletSelectors.getActive);
  readonly dateDelivery$ = this.store.select(CartSelectors.nextDeliveryDate);
  readonly ordersProducts$ = this.store.select(orderSelectors.getOrdersProducts);
  readonly errorsDelayedOrder$ = this.store.select(orderSelectors.getErrorsDelayedOrder);
  readonly completedDelayedOrder$ = this.store.select(orderSelectors.getCompletedDelayedOrder);

  modalRef: BsModalRef;
  protected unsubscribe$ = new Subject();

  constructor(private store: Store<AppState>, private modalService: BsModalService) {}

  ngOnInit() {
    this.store.dispatch(new CartActions.LoadDeliveryDate());
    this.errorsDelayedOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
      if (error) {
        this.handleError(error);
      }
    });
    this.completedDelayedOrder$.pipe(takeUntil(this.unsubscribe$)).subscribe((completed) => {
      if (completed) {
        this.closeModal();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.closed.emit();
    this.store.dispatch(new ResetUpdateDelayedOrder());
  }

  get totalPrice() {
    return this.orderCarts && this.orderCarts.reduce((prev, curr) => prev + (curr.ndsPrice * curr.quantity || 0), 0);
  }

  get cartAmount() {
    return this.orderCarts && this.orderCarts.length;
  }

  onIncrementAmount(orderCart: DelayedOrderCart) {
    this.orderCarts = this.orderCarts.map((item) => (item.id === orderCart.id ? { ...item, quantity: item.quantity + 1 } : item));
  }

  onDecrementAmount(orderCart: DelayedOrderCart) {
    this.orderCarts = this.orderCarts.map((item) => (item.id === orderCart.id ? { ...item, quantity: item.quantity - 1 } : item));
  }

  onDeleteItem(orderCart: DelayedOrderCart) {
    this.orderCarts = this.orderCarts.filter((item) => item.id !== orderCart.id);
  }

  onSaveOrder() {
    const payload: DelayedOrderUpdatePayload = {
      delayedOrderId: this.delayedOrder.id,
      remark: null,
      carts: this.orderCarts,
    };
    this.store.dispatch(new UpdateDelayedOrder(payload));
  }

  onCancel() {
    this.closeModal();
  }

  onClosedNotify() {
    this.modalRef?.hide();
  }

  private onShowMinimalNotify(minimalCartRuleCart: MinimalCartRules[]) {
    this.modalRef = this.modalService.show(DelayedOrderUpdateNotifyComponent, {
      class: 'modal-dialog-centered modal-notify',
      initialState: { minimalCartRuleCart },
    });
    this.modalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedNotify();
    });
  }

  private handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 422: {
        this.onShowMinimalNotify(error.error);
        break;
      }
      default:
        console.log(error);
        break;
    }
  }
}
