import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { TaskStepTypes } from '../../task-sales-agent.model';

@Component({
  selector: 'app-task-sales-agent-view-step',
  templateUrl: './task-sales-agent-view-step.component.html',
  styleUrls: ['./task-sales-agent-view-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSalesAgentViewStepComponent implements OnInit {
  @Input() status: string;
  @Input() type: string = TaskStepTypes.SELECT_OPTION;
  stepTypeMap = {
    [TaskStepTypes.SELECT_OPTION]: {
      title: 'Анкетирование',
      type: TaskStepTypes.SELECT_OPTION,
      description: `Пожалуйста, ответьте на ниже представленные вопросы для завершение шага анкетирования.
       Необходимо выбрать ответ на вопрос исходя из представленных вариантов.`,
      icon: 'assets/images/task-step/questionnaire.png',
    },
    [TaskStepTypes.GIVE_ARBITRARY_ANSWER]: {
      title: 'Свободная форма',
      type: TaskStepTypes.GIVE_ARBITRARY_ANSWER,
      description: ``,
      icon: 'assets/images/task-step/freeform-specification.png',
    },
    [TaskStepTypes.CALCULATE_REST_OF_PRODUCT]: {
      title: 'Остатки',
      type: TaskStepTypes.CALCULATE_REST_OF_PRODUCT,
      description: `При снятие товарного запаса укажите точное количество ваших остатков в приложение,
       как это продемонстрировано ниже и ваше задание будет отправлено на модерацию.`,
      icon: 'assets/images/task-step/leftovers.png',
    },
    [TaskStepTypes.PHOTO_MONITORING]: {
      title: 'Фото-мониторинг стеллажей',
      type: TaskStepTypes.PHOTO_MONITORING,
      description: `Сделайте одну общую фотографию стеллажа с четкой видимостью слотов производителя.`,
      icon: 'assets/images/task-step/photo-monitoring.png',
    },
    [TaskStepTypes.SEND_PHOTO]: {
      title: 'Сделать фото',
      type: TaskStepTypes.SEND_PHOTO,
      description: `При снятие товарного запаса укажите точное количество ваших остатков в приложение,
       как это продемонстрировано ниже и ваше задание будет отправлено на модерацию.`,
      icon: 'assets/images/task-step/make-photo.png',
    },
    [TaskStepTypes.AUTO_RECOMMENDED_ORDER]: {
      title: 'Авто-заказ',
      type: TaskStepTypes.AUTO_RECOMMENDED_ORDER,
      description: `Выполните авто-заказ из представленных позиций товаров. Нажмите “Заказать”,
       чтобы посмотреть прогресс, товары и для совершения авто-заказа.`,
      icon: 'assets/images/task-step/auto-order.png',
    },
  };

  constructor() {}

  ngOnInit(): void {}
}
