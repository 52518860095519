import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksSalesAgentComponent } from './tasks-sales-agent.component';
import { TasksSalesAgentItemComponent } from './tasks-sales-agent-item/tasks-sales-agent-item.component';
import { TasksSalesAgentFiltersComponent } from './tasks-sales-agent-filters/tasks-sales-agent-filters.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TasksSalesAgentV2ItemComponent } from './tasks-sales-agent-v2-item/tasks-sales-agent-v2-item.component';
import { TasksSalesAgentV2FilterComponent } from './tasks-sales-agent-v2-filter/tasks-sales-agent-v2-filter.component';
import { TasksSalesAgentFilterWeekComponent } from './tasks-sales-agent-filter-week/tasks-sales-agent-filter-week.component';
import { TasksSalesAgentFilterCalendarComponent } from './tasks-sales-agent-filter-calendar/tasks-sales-agent-filter-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TasksSalesAgentTagsModule } from './tasks-sales-agent-tags/tasks-sales-agent-tags.module';
import { TaskSalesAgentViewModule } from './task-sales-agent-view/task-sales-agent-view.module';

@NgModule({
  declarations: [
    TasksSalesAgentComponent,
    TasksSalesAgentItemComponent,
    TasksSalesAgentFiltersComponent,
    TasksSalesAgentV2ItemComponent,
    TasksSalesAgentV2FilterComponent,
    TasksSalesAgentFilterWeekComponent,
    TasksSalesAgentFilterCalendarComponent,
  ],
  exports: [TasksSalesAgentComponent],
  imports: [
    CommonModule,
    ProgressbarModule.forRoot(),
    SwiperModule,
    FullCalendarModule,
    TasksSalesAgentTagsModule,
    TaskSalesAgentViewModule,
  ],
})
export class TasksSalesAgentModule {}
