import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap, map, debounceTime } from 'rxjs/operators';
import { pagesToggleService } from '../../../@pages/services/toggler.service';
import { AppState } from '../../../store/app.state';
import { CartActions, CartSelectors } from '../../../store/cart';
import { LoadMinimalCartRuleGroupedStatus } from '../../../store/cart/cart.actions';
import { CartItemDisplay, CartRuleGroupedStatus, MinimalCartRuleGroupedStatus } from '../../../store/cart/cart.models';
import { LoadBonusesRecommendedOrders } from '../../../store/order/order.actions';
import { orderSelectors } from '../../../store/order/order.selectors';
import { BasketFillMinimalAlertComponent } from './basket-fill-minimal-alert/basket-fill-minimal-alert.component';
export enum BasketStateType {
  TASK = 'task',
  CATALOG = 'catalog',
  DEFAULT = 'default',
}
@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketComponent implements OnInit, OnDestroy {
  minimalCartRulesGroupStatus$: Observable<MinimalCartRuleGroupedStatus>;
  CartRuleGroupedStatus = CartRuleGroupedStatus;
  readonly cart$ = this.store.select(CartSelectors.selectProductsForCartDisplay);
  readonly totalCartItems$ = this.store.select(CartSelectors.numberOfCartItems);
  readonly totalAmountOfOrder$ = this.store.select(CartSelectors.totalAmountOfOrder);
  readonly totalOrderPrice$ = this.store.select(CartSelectors.selectTotalPriceOfOrder);
  protected unsubscribe$ = new Subject();
  readonly recommendedCart$ = this.store.select(CartSelectors.selectRecommendedCart);
  readonly numberOfRecommendedCart$ = this.store.select(CartSelectors.numberOfRecommendedCart);
  readonly numberOfOtherCart$ = this.store.select(CartSelectors.numberOfOtherCart);
  readonly numberOfRecommendCart$ = this.store.select(CartSelectors.numberOfRecommendCart);
  bonusRecommendedOrders$ = this.store.pipe(select(orderSelectors.getBonusRecommendedOrders));
  readonly showOrderDetails$ = this.store.select(CartSelectors.showOrderDetails);
  stateCart: string = BasketStateType.DEFAULT;
  recommendedOrderId: number;
  modalRef: BsModalRef;
  cartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  cartByDistributors$ = this.store.select(CartSelectors.selectProductsForCartDisplay).pipe(
    map((products: CartItemDisplay[]) => {
      const distributors: any = {};
      products.forEach((product) => {
        if (!distributors[product.distributorName]) {
          distributors[product.distributorName] = [];
        }
        distributors[product.distributorName].push(product);
      });
      return Object.values(distributors);
    })
  );

  constructor(
    private toggler: pagesToggleService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.store.dispatch(new CartActions.LoadCart());
    this.minimalCartRulesGroupStatus$ = this.store.pipe(
      select(CartSelectors.selectMinimalCartRulesGroupStatus),
      tap((status) => (this.cartRulesGroupStatus = status))
    );
    this.store.pipe(select(CartSelectors.selectCart), takeUntil(this.unsubscribe$)).subscribe((cartList) => {
      if (cartList && cartList.length) {
        this.store.dispatch(new LoadMinimalCartRuleGroupedStatus());
      }
    });
    this.handleParams();
    this.toggler.basketToggle.subscribe((open) => {
      if (!open) {
        if (this.recommendedOrderId) {
          this.recommendedOrderId = null;
        }
        this.onClosedRecommendedOrder();
        this.store.dispatch(new CartActions.ClearOnOrderCompletedState());
      }
    });
  }

  getBasketList(): Observable<unknown[]> {
    return this.cart$.pipe(
      map((products: CartItemDisplay[]) => {
        let distributors: any = {};
        products.forEach((product) => {
          if (!distributors[product.distributorName]) {
            distributors[product.distributorName] = [];
          }
          distributors[product.distributorName].push(product);
        });
        return Object.values(distributors);
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleParams(): void {
    this.route.queryParamMap.pipe(takeUntil(this.unsubscribe$)).subscribe((paramsMap) => {
      this.initTypesBasket(paramsMap);
    });
  }

  order() {
    this.store.dispatch(new CartActions.SubmitCart());
  }

  incrementAmount(product: CartItemDisplay) {
    this.store.dispatch(
      new CartActions.IncrementProductInCart({
        id: product.id,
        unit: product.unit,
        materialId: product.materialId,
        distributorName: product.distributorName,
      })
    );
  }

  decrementAmount(product: CartItemDisplay) {
    this.store.dispatch(
      new CartActions.DecrementProductInCart({
        id: product.id,
        unit: product.unit,
        materialId: product.materialId,
        distributorName: product.distributorName,
      })
    );
  }

  deleteCartItem(product: CartItemDisplay) {
    this.store.dispatch(new CartActions.DeleteProduct(product));
  }

  onClosedRecommendedOrder() {
    this.store.dispatch(new CartActions.ResetRecommendedOrderInCart());
    this.store.dispatch(new CartActions.LoadCart());
    this.stateCart = BasketStateType.DEFAULT;
  }

  closeCart() {
    this.toggler.toggleBacket(false);
  }

  private initTypesBasket(params: ParamMap) {
    if (params.has('action') && params.get('action') === 'basket') {
      this.recommendedOrderId = null;
      this.stateCart = BasketStateType.DEFAULT;
      if (params.has('action_type')) {
        switch (params.get('action_type')) {
          case 'task': {
            this.stateCart = BasketStateType.TASK;
            this.cdr.markForCheck();
            if (params.has('action_id')) {
              this.recommendedOrderId = +params.get('action_id');
              this.store.dispatch(
                new LoadBonusesRecommendedOrders({ recommendedOrderId: this.recommendedOrderId, executed: false, predict: true })
              );
            }
            break;
          }

          case 'catalog': {
            this.stateCart = BasketStateType.CATALOG;
            this.cdr.markForCheck();
            break;
          }

          default:
            this.stateCart = BasketStateType.DEFAULT;
            this.cdr.markForCheck();
            break;
        }
      }
    }
  }

  onChangeOrder(recommendedOrderId: number) {
    this.recommendedOrderId = recommendedOrderId;
    if (recommendedOrderId) {
      this.store.dispatch(new CartActions.LoadRecommendedOrderInCart({ recommendedOrderId }));
    }
  }

  onShowFillMinimalModal() {
    this.modalRef = this.modalService.show(BasketFillMinimalAlertComponent, {
      class: 'modal-dialog-centered',
      initialState: { minimalCartRuleCart: this.cartRulesGroupStatus.minimalCartRuleCartResponses },
    });
    this.modalRef.content.canceled.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.onClosedFillMinimalModal();
    });
    this.modalRef.content.ordered.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.order();
      this.onClosedFillMinimalModal();
    });
    this.modalRef.content.minimalAdded.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.minimalAddedToCart();
    });
  }

  onClosedFillMinimalModal() {
    this.modalRef?.hide();
  }

  minimalAddedToCart() {
    this.store.dispatch(new CartActions.FillUpCartsMinimalLimit());
    this.onClosedFillMinimalModal();
  }

  onOrder() {
    if (
      this.cartRulesGroupStatus &&
      (this.cartRulesGroupStatus.status === CartRuleGroupedStatus.NotAvailable ||
        this.cartRulesGroupStatus.status === CartRuleGroupedStatus.PartialAvailable)
    ) {
      this.onShowFillMinimalModal();
    } else {
      this.order();
    }
  }
}
