import { User } from '@Mesh/core/models/user';
import { createDefaultLoadable, Loadable } from '@Mesh/utils/loadable/loadable';
import { POSTRequestStatus } from '@Mesh/store/user/user.state';
import { Entity } from '@Mesh/core/models/login';

export interface LoginState extends Loadable {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  user: User | null;
  // error message
  errors: any;
  token: string | null;
  canBeAuthorized: boolean | null;
  identifier: number | null;
  identifierParticipant: string;
  documents: any[] | null;
  name: string | null;
  inn: string | null;
  phoneNumber: string | null;
  applicationStatus: boolean;
  acceptCodeSuccess: boolean;
  updatePasswordIdentifier: number | null;
  updatePasswordSmsVerifyStatus: POSTRequestStatus;
  updatePasswordVerifyStatus: POSTRequestStatus;
  newUserPassword: string | null;
  entities: Array<Entity>;
  shouldCreatePassword: boolean;
  passwordSetSuccess: boolean;
}

export const initialState: LoginState = {
  ...createDefaultLoadable(),
  isAuthenticated: false,
  user: null,
  errors: null,
  token: null,
  canBeAuthorized: null,
  identifier: null,
  documents: null,
  name: null,
  inn: null,
  phoneNumber: null,
  applicationStatus: false,
  acceptCodeSuccess: false,
  updatePasswordIdentifier: null,
  updatePasswordSmsVerifyStatus: POSTRequestStatus.NONE,
  updatePasswordVerifyStatus: POSTRequestStatus.NONE,
  newUserPassword: null,
  entities: [],
  shouldCreatePassword: false,
  passwordSetSuccess: false,
  identifierParticipant: null,
};
